// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/roles.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetRolesRequest, GetRolesResponse } from "./roles_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.RoleService
 */
export const RoleService = {
  typeName: "avn.connect.v1.RoleService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.RoleService.GetRoles
     */
    getRoles: {
      name: "GetRoles",
      I: GetRolesRequest,
      O: GetRolesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

