// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/languages.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetLanguagesRequest, GetLanguagesResponse } from "./languages_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.LanguageService
 */
export const LanguageService = {
  typeName: "avn.connect.v1.LanguageService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.LanguageService.GetLanguages
     */
    getLanguages: {
      name: "GetLanguages",
      I: GetLanguagesRequest,
      O: GetLanguagesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

