// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/ktx.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ToKtxRequest, ToKtxResponse } from "./ktx_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.KtxService
 */
export const KtxService = {
  typeName: "avn.connect.v1.KtxService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.KtxService.ToKtx
     */
    toKtx: {
      name: "ToKtx",
      I: ToKtxRequest,
      O: ToKtxResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

