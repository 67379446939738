// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/users.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetEntityRequest } from "./entities_pb.js";
import { User } from "./user_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { GetOrganizationMembershipRequest, GetOrganizationMembershipResponse, SearchMemberOrganizationsRequest, SearchMemberOrganizationsResponse } from "./users_pb.js";

/**
 * @generated from service avn.connect.v1.UserService
 */
export const UserService = {
  typeName: "avn.connect.v1.UserService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.UserService.GetUser
     */
    getUser: {
      name: "GetUser",
      I: GetEntityRequest,
      O: User,
      kind: MethodKind.Unary,
    },
    /**
     * Get the organizations which the user directly belongs to
     *
     * @generated from rpc avn.connect.v1.UserService.GetOrganizationMembership
     */
    getOrganizationMembership: {
      name: "GetOrganizationMembership",
      I: GetOrganizationMembershipRequest,
      O: GetOrganizationMembershipResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Search organizations the user belongs to directly and by inheritence
     *
     * @generated from rpc avn.connect.v1.UserService.SearchMemberOrganizations
     */
    searchMemberOrganizations: {
      name: "SearchMemberOrganizations",
      I: SearchMemberOrganizationsRequest,
      O: SearchMemberOrganizationsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

