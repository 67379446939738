// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/content.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MatchActivityFromCloudRequest, MatchActivityFromFilesRequest, MatchActivityFromUrlRequest, MatchActivityResponse, SubmitCommunityCategoryRequest } from "./content_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.ContentService
 */
export const ContentService = {
  typeName: "avn.connect.v1.ContentService",
  methods: {
    /**
     * Find or create an activity that matches the given cloud file
     *
     * @generated from rpc avn.connect.v1.ContentService.MatchActivityFromCloud
     */
    matchActivityFromCloud: {
      name: "MatchActivityFromCloud",
      I: MatchActivityFromCloudRequest,
      O: MatchActivityResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Find or create an activity that matches the given file URLs
     *
     * @generated from rpc avn.connect.v1.ContentService.MatchActivityFromFiles
     */
    matchActivityFromFiles: {
      name: "MatchActivityFromFiles",
      I: MatchActivityFromFilesRequest,
      O: MatchActivityResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Find or create an activity that matches the given web link
     *
     * @generated from rpc avn.connect.v1.ContentService.MatchActivityFromUrl
     */
    matchActivityFromUrl: {
      name: "MatchActivityFromUrl",
      I: MatchActivityFromUrlRequest,
      O: MatchActivityResponse,
      kind: MethodKind.Unary,
    },
    /**
     * User submissions for community library
     *
     * @generated from rpc avn.connect.v1.ContentService.SubmitCommunityCategory
     */
    submitCommunityCategory: {
      name: "SubmitCommunityCategory",
      I: SubmitCommunityCategoryRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

