// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/organization_membership.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message avn.connect.v1.OrganizationMembership
 */
export class OrganizationMembership extends Message<OrganizationMembership> {
  /**
   * @generated from field: int32 user_id = 1;
   */
  userId = 0;

  /**
   * @generated from field: int32 organization_id = 2;
   */
  organizationId = 0;

  /**
   * @generated from field: int32 role_id = 3;
   */
  roleId = 0;

  /**
   * @generated from field: repeated string additional_permissions = 4;
   */
  additionalPermissions: string[] = [];

  constructor(data?: PartialMessage<OrganizationMembership>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.OrganizationMembership";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "role_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "additional_permissions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrganizationMembership {
    return new OrganizationMembership().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrganizationMembership {
    return new OrganizationMembership().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrganizationMembership {
    return new OrganizationMembership().fromJsonString(jsonString, options);
  }

  static equals(a: OrganizationMembership | PlainMessage<OrganizationMembership> | undefined, b: OrganizationMembership | PlainMessage<OrganizationMembership> | undefined): boolean {
    return proto3.util.equals(OrganizationMembership, a, b);
  }
}

