// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/interaction_permissions.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message avn.connect.v1.InteractionPermissions
 */
export class InteractionPermissions extends Message<InteractionPermissions> {
  /**
   * Should the BACK button be enabled?
   *
   * @generated from field: bool allow_back = 1;
   */
  allowBack = false;

  /**
   * Can the user select a new scene? 
   *
   * @generated from field: bool allow_explore = 2;
   */
  allowExplore = false;

  /**
   * Can the user follow links between rooms?
   *
   * @generated from field: bool allow_navigation = 3;
   */
  allowNavigation = false;

  /**
   * Can the user invite others?
   *
   * @generated from field: bool allow_invite = 10;
   */
  allowInvite = false;

  /**
   * Can the user issue hall passes?
   *
   * @generated from field: bool allow_pass = 11;
   */
  allowPass = false;

  /**
   * Can the user change their avatar?
   *
   * @generated from field: bool allow_change_avatar = 12;
   */
  allowChangeAvatar = false;

  /**
   * Should the teacher notes be shown?
   *
   * @generated from field: bool allow_teacher_notes = 13;
   */
  allowTeacherNotes = false;

  /**
   * Should the student notes be shown?
   *
   * @generated from field: bool allow_student_notes = 14;
   */
  allowStudentNotes = false;

  /**
   * Should the list of people in the room be visible?
   *
   * @generated from field: bool allow_people_menu = 15;
   */
  allowPeopleMenu = false;

  /**
   * Can the user change lesson focus?
   *
   * @generated from field: bool allow_focus = 20;
   */
  allowFocus = false;

  /**
   * Can the user mute everyone?
   *
   * @generated from field: bool allow_mute = 21;
   */
  allowMute = false;

  /**
   * Can the user send messages to every room in the dimension?
   *
   * @generated from field: bool allow_broadcast = 22;
   */
  allowBroadcast = false;

  /**
   * Is VoIP enabled?
   *
   * @generated from field: bool allow_voip = 31;
   */
  allowVoip = false;

  /**
   * Is text chat enabled?
   *
   * @generated from field: bool allow_text = 32;
   */
  allowText = false;

  /**
   * Can user move media?
   *
   * @generated from field: bool allow_move_media = 33;
   */
  allowMoveMedia = false;

  /**
   * Can user pin media?
   *
   * @generated from field: bool allow_pin_media = 34;
   */
  allowPinMedia = false;

  /**
   * Can user show reactions?
   *
   * @generated from field: bool allow_react = 35;
   */
  allowReact = false;

  /**
   * Can user share upload media to a room?
   *
   * @generated from field: bool allow_share_media = 36;
   */
  allowShareMedia = false;

  /**
   * Can user share their screen?
   *
   * @generated from field: bool allow_share_screen = 37;
   */
  allowShareScreen = false;

  /**
   * Can user fly?
   *
   * @generated from field: bool allow_fly = 38;
   */
  allowFly = false;

  /**
   * Can user write with the pen tool?
   *
   * @generated from field: bool allow_pen = 39;
   */
  allowPen = false;

  constructor(data?: PartialMessage<InteractionPermissions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.InteractionPermissions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "allow_back", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "allow_explore", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "allow_navigation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "allow_invite", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "allow_pass", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "allow_change_avatar", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "allow_teacher_notes", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "allow_student_notes", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "allow_people_menu", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "allow_focus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "allow_mute", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "allow_broadcast", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 31, name: "allow_voip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 32, name: "allow_text", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 33, name: "allow_move_media", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 34, name: "allow_pin_media", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 35, name: "allow_react", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 36, name: "allow_share_media", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 37, name: "allow_share_screen", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 38, name: "allow_fly", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 39, name: "allow_pen", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InteractionPermissions {
    return new InteractionPermissions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InteractionPermissions {
    return new InteractionPermissions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InteractionPermissions {
    return new InteractionPermissions().fromJsonString(jsonString, options);
  }

  static equals(a: InteractionPermissions | PlainMessage<InteractionPermissions> | undefined, b: InteractionPermissions | PlainMessage<InteractionPermissions> | undefined): boolean {
    return proto3.util.equals(InteractionPermissions, a, b);
  }
}

