// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/clients.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateClientCredentialsRequest, CreateClientCredentialsResponse, GetLighthouseServersRequest, GetLighthouseServersResponse, RecordActionRequest, RecordFeedbackRequest, RegisterLighthouseServerRequest, RegisterLighthouseServerResponse } from "./clients_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.ClientService
 */
export const ClientService = {
  typeName: "avn.connect.v1.ClientService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.ClientService.CreateClientCredentials
     */
    createClientCredentials: {
      name: "CreateClientCredentials",
      I: CreateClientCredentialsRequest,
      O: CreateClientCredentialsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Record arbitrary actions that might not be captured in other data flows
     *
     * @generated from rpc avn.connect.v1.ClientService.RecordAction
     */
    recordAction: {
      name: "RecordAction",
      I: RecordActionRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Record user feedback
     *
     * @generated from rpc avn.connect.v1.ClientService.RecordFeedback
     */
    recordFeedback: {
      name: "RecordFeedback",
      I: RecordFeedbackRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Lighthouse servers provide stateless DNS resolution checking for avnlan.local style addresses
     *
     * @generated from rpc avn.connect.v1.ClientService.RegisterLighthouseServer
     */
    registerLighthouseServer: {
      name: "RegisterLighthouseServer",
      I: RegisterLighthouseServerRequest,
      O: RegisterLighthouseServerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.ClientService.GetLighthouseServers
     */
    getLighthouseServers: {
      name: "GetLighthouseServers",
      I: GetLighthouseServersRequest,
      O: GetLighthouseServersResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

