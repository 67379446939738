// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/languages.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum avn.connect.v1.TextDirection
 */
export enum TextDirection {
  /**
   * @generated from enum value: TEXT_DIRECTION_LTR = 0;
   */
  LTR = 0,

  /**
   * @generated from enum value: TEXT_DIRECTION_RTL = 1;
   */
  RTL = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(TextDirection)
proto3.util.setEnumType(TextDirection, "avn.connect.v1.TextDirection", [
  { no: 0, name: "TEXT_DIRECTION_LTR" },
  { no: 1, name: "TEXT_DIRECTION_RTL" },
]);

/**
 * @generated from message avn.connect.v1.Language
 */
export class Language extends Message<Language> {
  /**
   * ID
   *
   * @generated from field: string language_id = 1;
   */
  languageId = "";

  /**
   * Name (in the language itself)
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * Name in English
   *
   * @generated from field: string name_english = 3;
   */
  nameEnglish = "";

  /**
   * Direction
   *
   * @generated from field: avn.connect.v1.TextDirection dir = 4;
   */
  dir = TextDirection.LTR;

  /**
   * ClassConnect content tag
   *
   * @generated from field: optional int32 tag_id = 5;
   */
  tagId?: number;

  constructor(data?: PartialMessage<Language>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Language";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name_english", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "dir", kind: "enum", T: proto3.getEnumType(TextDirection) },
    { no: 5, name: "tag_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Language {
    return new Language().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Language {
    return new Language().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Language {
    return new Language().fromJsonString(jsonString, options);
  }

  static equals(a: Language | PlainMessage<Language> | undefined, b: Language | PlainMessage<Language> | undefined): boolean {
    return proto3.util.equals(Language, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetLanguagesRequest
 */
export class GetLanguagesRequest extends Message<GetLanguagesRequest> {
  constructor(data?: PartialMessage<GetLanguagesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetLanguagesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLanguagesRequest {
    return new GetLanguagesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLanguagesRequest {
    return new GetLanguagesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLanguagesRequest {
    return new GetLanguagesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetLanguagesRequest | PlainMessage<GetLanguagesRequest> | undefined, b: GetLanguagesRequest | PlainMessage<GetLanguagesRequest> | undefined): boolean {
    return proto3.util.equals(GetLanguagesRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetLanguagesResponse
 */
export class GetLanguagesResponse extends Message<GetLanguagesResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.Language languages = 1;
   */
  languages: Language[] = [];

  constructor(data?: PartialMessage<GetLanguagesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetLanguagesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "languages", kind: "message", T: Language, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLanguagesResponse {
    return new GetLanguagesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLanguagesResponse {
    return new GetLanguagesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLanguagesResponse {
    return new GetLanguagesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetLanguagesResponse | PlainMessage<GetLanguagesResponse> | undefined, b: GetLanguagesResponse | PlainMessage<GetLanguagesResponse> | undefined): boolean {
    return proto3.util.equals(GetLanguagesResponse, a, b);
  }
}

