// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/clients.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Struct, Timestamp } from "@bufbuild/protobuf";
import { Authorization } from "./authorization_pb.js";
import { EntityPropertyState } from "./entities_pb.js";

/**
 * @generated from message avn.connect.v1.ClientCredentials
 */
export class ClientCredentials extends Message<ClientCredentials> {
  /**
   * @generated from field: string client_id = 1;
   */
  clientId = "";

  /**
   * @generated from field: optional string client_secret = 2;
   */
  clientSecret?: string;

  constructor(data?: PartialMessage<ClientCredentials>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.ClientCredentials";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "client_secret", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClientCredentials {
    return new ClientCredentials().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClientCredentials {
    return new ClientCredentials().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClientCredentials {
    return new ClientCredentials().fromJsonString(jsonString, options);
  }

  static equals(a: ClientCredentials | PlainMessage<ClientCredentials> | undefined, b: ClientCredentials | PlainMessage<ClientCredentials> | undefined): boolean {
    return proto3.util.equals(ClientCredentials, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.RecordActionRequest
 */
export class RecordActionRequest extends Message<RecordActionRequest> {
  /**
   * @generated from field: avn.connect.v1.ClientCredentials client = 1;
   */
  client?: ClientCredentials;

  /**
   * The action identifier
   *
   * @generated from field: string action_id = 2;
   */
  actionId = "";

  /**
   * The source of the action: button, anchor, etc...
   *
   * @generated from field: string source_id = 3;
   */
  sourceId = "";

  /**
   * The hosting platform, typically the web page hostname
   *
   * @generated from field: string host_id = 6;
   */
  hostId = "";

  /**
   * Optional extra data associated with the action
   *
   * @generated from field: optional google.protobuf.Struct data = 4;
   */
  data?: Struct;

  /**
   * Optional authorization info to provide context and permission
   *
   * @generated from field: optional avn.connect.v1.Authorization auth = 5;
   */
  auth?: Authorization;

  constructor(data?: PartialMessage<RecordActionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.RecordActionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client", kind: "message", T: ClientCredentials },
    { no: 2, name: "action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "source_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "host_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: Struct, opt: true },
    { no: 5, name: "auth", kind: "message", T: Authorization, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecordActionRequest {
    return new RecordActionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecordActionRequest {
    return new RecordActionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecordActionRequest {
    return new RecordActionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RecordActionRequest | PlainMessage<RecordActionRequest> | undefined, b: RecordActionRequest | PlainMessage<RecordActionRequest> | undefined): boolean {
    return proto3.util.equals(RecordActionRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.RecordFeedbackRequest
 */
export class RecordFeedbackRequest extends Message<RecordFeedbackRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * The feedback identifier
   *
   * @generated from field: string feedback_id = 2;
   */
  feedbackId = "";

  /**
   * The primary feedback reason ID
   *
   * @generated from field: string feedback_reason = 3;
   */
  feedbackReason = "";

  /**
   * Freeform details supplied by the user
   *
   * @generated from field: optional string feedback_detail = 4;
   */
  feedbackDetail?: string;

  /**
   * Optional extra structured data
   *
   * @generated from field: optional google.protobuf.Struct data = 5;
   */
  data?: Struct;

  constructor(data?: PartialMessage<RecordFeedbackRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.RecordFeedbackRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "feedback_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "feedback_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "feedback_detail", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "data", kind: "message", T: Struct, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecordFeedbackRequest {
    return new RecordFeedbackRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecordFeedbackRequest {
    return new RecordFeedbackRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecordFeedbackRequest {
    return new RecordFeedbackRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RecordFeedbackRequest | PlainMessage<RecordFeedbackRequest> | undefined, b: RecordFeedbackRequest | PlainMessage<RecordFeedbackRequest> | undefined): boolean {
    return proto3.util.equals(RecordFeedbackRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.CreateClientCredentialsRequest
 */
export class CreateClientCredentialsRequest extends Message<CreateClientCredentialsRequest> {
  /**
   * Useful for user-friendly naming
   *
   * @generated from field: optional string prefix = 1;
   */
  prefix?: string;

  /**
   * @generated from field: optional string postfix = 2;
   */
  postfix?: string;

  /**
   * Fingerprint of client
   *
   * @generated from field: repeated avn.connect.v1.EntityPropertyState client_state = 3;
   */
  clientState: EntityPropertyState[] = [];

  constructor(data?: PartialMessage<CreateClientCredentialsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CreateClientCredentialsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "prefix", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "postfix", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "client_state", kind: "message", T: EntityPropertyState, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateClientCredentialsRequest {
    return new CreateClientCredentialsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateClientCredentialsRequest {
    return new CreateClientCredentialsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateClientCredentialsRequest {
    return new CreateClientCredentialsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateClientCredentialsRequest | PlainMessage<CreateClientCredentialsRequest> | undefined, b: CreateClientCredentialsRequest | PlainMessage<CreateClientCredentialsRequest> | undefined): boolean {
    return proto3.util.equals(CreateClientCredentialsRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.CreateClientCredentialsResponse
 */
export class CreateClientCredentialsResponse extends Message<CreateClientCredentialsResponse> {
  /**
   * @generated from field: avn.connect.v1.ClientCredentials client_credentials = 1;
   */
  clientCredentials?: ClientCredentials;

  constructor(data?: PartialMessage<CreateClientCredentialsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CreateClientCredentialsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_credentials", kind: "message", T: ClientCredentials },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateClientCredentialsResponse {
    return new CreateClientCredentialsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateClientCredentialsResponse {
    return new CreateClientCredentialsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateClientCredentialsResponse {
    return new CreateClientCredentialsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateClientCredentialsResponse | PlainMessage<CreateClientCredentialsResponse> | undefined, b: CreateClientCredentialsResponse | PlainMessage<CreateClientCredentialsResponse> | undefined): boolean {
    return proto3.util.equals(CreateClientCredentialsResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.LighthouseServer
 */
export class LighthouseServer extends Message<LighthouseServer> {
  /**
   * @generated from field: string lighthouse_id = 1;
   */
  lighthouseId = "";

  /**
   * @generated from field: string wanAddress = 2;
   */
  wanAddress = "";

  /**
   * @generated from field: string wanHostname = 3;
   */
  wanHostname = "";

  /**
   * @generated from field: google.protobuf.Timestamp registered = 4;
   */
  registered?: Timestamp;

  constructor(data?: PartialMessage<LighthouseServer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.LighthouseServer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lighthouse_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wanAddress", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "wanHostname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "registered", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LighthouseServer {
    return new LighthouseServer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LighthouseServer {
    return new LighthouseServer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LighthouseServer {
    return new LighthouseServer().fromJsonString(jsonString, options);
  }

  static equals(a: LighthouseServer | PlainMessage<LighthouseServer> | undefined, b: LighthouseServer | PlainMessage<LighthouseServer> | undefined): boolean {
    return proto3.util.equals(LighthouseServer, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.RegisterLighthouseServerRequest
 */
export class RegisterLighthouseServerRequest extends Message<RegisterLighthouseServerRequest> {
  /**
   * @generated from field: avn.connect.v1.ClientCredentials client = 1;
   */
  client?: ClientCredentials;

  constructor(data?: PartialMessage<RegisterLighthouseServerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.RegisterLighthouseServerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client", kind: "message", T: ClientCredentials },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterLighthouseServerRequest {
    return new RegisterLighthouseServerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterLighthouseServerRequest {
    return new RegisterLighthouseServerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterLighthouseServerRequest {
    return new RegisterLighthouseServerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterLighthouseServerRequest | PlainMessage<RegisterLighthouseServerRequest> | undefined, b: RegisterLighthouseServerRequest | PlainMessage<RegisterLighthouseServerRequest> | undefined): boolean {
    return proto3.util.equals(RegisterLighthouseServerRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.RegisterLighthouseServerResponse
 */
export class RegisterLighthouseServerResponse extends Message<RegisterLighthouseServerResponse> {
  /**
   * @generated from field: avn.connect.v1.LighthouseServer server = 1;
   */
  server?: LighthouseServer;

  constructor(data?: PartialMessage<RegisterLighthouseServerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.RegisterLighthouseServerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "server", kind: "message", T: LighthouseServer },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterLighthouseServerResponse {
    return new RegisterLighthouseServerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterLighthouseServerResponse {
    return new RegisterLighthouseServerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterLighthouseServerResponse {
    return new RegisterLighthouseServerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterLighthouseServerResponse | PlainMessage<RegisterLighthouseServerResponse> | undefined, b: RegisterLighthouseServerResponse | PlainMessage<RegisterLighthouseServerResponse> | undefined): boolean {
    return proto3.util.equals(RegisterLighthouseServerResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetLighthouseServersRequest
 */
export class GetLighthouseServersRequest extends Message<GetLighthouseServersRequest> {
  constructor(data?: PartialMessage<GetLighthouseServersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetLighthouseServersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLighthouseServersRequest {
    return new GetLighthouseServersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLighthouseServersRequest {
    return new GetLighthouseServersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLighthouseServersRequest {
    return new GetLighthouseServersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetLighthouseServersRequest | PlainMessage<GetLighthouseServersRequest> | undefined, b: GetLighthouseServersRequest | PlainMessage<GetLighthouseServersRequest> | undefined): boolean {
    return proto3.util.equals(GetLighthouseServersRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetLighthouseServersResponse
 */
export class GetLighthouseServersResponse extends Message<GetLighthouseServersResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.LighthouseServer servers = 1;
   */
  servers: LighthouseServer[] = [];

  constructor(data?: PartialMessage<GetLighthouseServersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetLighthouseServersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "servers", kind: "message", T: LighthouseServer, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLighthouseServersResponse {
    return new GetLighthouseServersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLighthouseServersResponse {
    return new GetLighthouseServersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLighthouseServersResponse {
    return new GetLighthouseServersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetLighthouseServersResponse | PlainMessage<GetLighthouseServersResponse> | undefined, b: GetLighthouseServersResponse | PlainMessage<GetLighthouseServersResponse> | undefined): boolean {
    return proto3.util.equals(GetLighthouseServersResponse, a, b);
  }
}

