// Hash utilities

import { Sha256 } from "./sha-256"
import * as Environment from "./environment-utils-client"

// Conversion functions

export function base64ArrayBufferToBase64UrlHashString(ab: ArrayBuffer): string {
    return btoa(String.fromCharCode(...new Uint8Array(ab))).replace(/\+/g, '-').replace(/\//g, '_').replace(/=*$/g, '')
}

// Hashing functions

export async function base64UrlHashForArrayBuffer(buffer: ArrayBuffer, signal: AbortSignal | undefined = undefined) : Promise<string> {
    // Note: moving this outside the function body causes a silent crash in the Eduverse web client
    const subtle = globalThis.crypto.subtle ?? (await import("crypto")).subtle
    if(!subtle) {
        throw new Error(`crypto.subtle not defined (probably insecure browser context)`)
    }
    return base64ArrayBufferToBase64UrlHashString(await subtle.digest("SHA-256", buffer))
}

export async function base64UrlHashForFile(file: File, signal: AbortSignal | undefined = undefined) {    
    try {
        return await base64UrlHashForArrayBuffer(await file.arrayBuffer())
    } catch(e: unknown) {
        // Large files can cause this to fail. For example, Chrome has a 2GB limit currently
        console.warn(`Unable to get arrayBuffer from file (probably browser limit). Slow streaming method will be used instead.`, e)
    }
    // Use the streaming interface instead (much slower)
    const sha = new Sha256()    
    const reader = file.stream().getReader()
    let isDone = false
    while(!isDone) {
        if(signal?.aborted) {
            throw new Error(`Operation aborted by caller`)
        }
        const { done, value } = await reader.read()
        if(value) {
            sha.update(value)
        }
        isDone = done
    }
    return base64ArrayBufferToBase64UrlHashString(sha.digest())
}

export async function base64UrlHashForFilePath(path: string) {
    const pipeline = Environment.isBrowser ? undefined : (await import("stream")).promises.pipeline
    if(!pipeline) {
        throw new Error("pipeline not available")
    }    
    const createHash = Environment.isBrowser ? undefined : (await import("crypto")).createHash
    if(!createHash) {
        throw new Error("createHash not available")
    }
    const fs = Environment.isBrowser ? undefined : await import("fs")
    if(!fs) {
        throw new Error("fs not available")
    }
    const readable = fs.createReadStream(path)
    const hash = createHash("sha256")
    await pipeline(readable, hash)
    return hash.digest("base64url")
}


