import { HashBase } from "./hash-base"

const K = [
  0x428A2F98, 0x71374491, 0xB5C0FBCF, 0xE9B5DBA5,
  0x3956C25B, 0x59F111F1, 0x923F82A4, 0xAB1C5ED5,
  0xD807AA98, 0x12835B01, 0x243185BE, 0x550C7DC3,
  0x72BE5D74, 0x80DEB1FE, 0x9BDC06A7, 0xC19BF174,
  0xE49B69C1, 0xEFBE4786, 0x0FC19DC6, 0x240CA1CC,
  0x2DE92C6F, 0x4A7484AA, 0x5CB0A9DC, 0x76F988DA,
  0x983E5152, 0xA831C66D, 0xB00327C8, 0xBF597FC7,
  0xC6E00BF3, 0xD5A79147, 0x06CA6351, 0x14292967,
  0x27B70A85, 0x2E1B2138, 0x4D2C6DFC, 0x53380D13,
  0x650A7354, 0x766A0ABB, 0x81C2C92E, 0x92722C85,
  0xA2BFE8A1, 0xA81A664B, 0xC24B8B70, 0xC76C51A3,
  0xD192E819, 0xD6990624, 0xF40E3585, 0x106AA070,
  0x19A4C116, 0x1E376C08, 0x2748774C, 0x34B0BCB5,
  0x391C0CB3, 0x4ED8AA4A, 0x5B9CCA4F, 0x682E6FF3,
  0x748F82EE, 0x78A5636F, 0x84C87814, 0x8CC70208,
  0x90BEFFFA, 0xA4506CEB, 0xBEF9A3F7, 0xC67178F2
]

export class Sha256 extends HashBase {
  constructor() {
    super(64, 56)
  }

  private _w = new Array(64)

  private _a = 0x6a09e667
  private _b = 0xbb67ae85
  private _c = 0x3c6ef372
  private _d = 0xa54ff53a
  private _e = 0x510e527f
  private _f = 0x9b05688c
  private _g = 0x1f83d9ab
  private _h = 0x5be0cd19

  private ch(x: number, y: number, z: number) : number {
    return z ^ (x & (y ^ z))
  }

  private maj(x: number, y: number, z: number) : number {
    return (x & y) | (z & (x | y))
  }

  private sigma0(x: number) : number {
    return (x >>> 2 | x << 30) ^ (x >>> 13 | x << 19) ^ (x >>> 22 | x << 10)
  }

  private sigma1(x: number) : number {
    return (x >>> 6 | x << 26) ^ (x >>> 11 | x << 21) ^ (x >>> 25 | x << 7)
  }

  private gamma0(x: number) : number {
    return (x >>> 7 | x << 25) ^ (x >>> 18 | x << 14) ^ (x >>> 3)
  }

  private gamma1(x: number) : number {
    return (x >>> 17 | x << 15) ^ (x >>> 19 | x << 13) ^ (x >>> 10)
  }

  protected override _update(M: Uint8Array) {
    let W = this._w

    let a = this._a | 0
    let b = this._b | 0
    let c = this._c | 0
    let d = this._d | 0
    let e = this._e | 0
    let f = this._f | 0
    let g = this._g | 0
    let h = this._h | 0

    const view = new DataView(M.buffer)
    for (let i = 0; i < 16; ++i) W[i] = view.getInt32(i * 4, false)
    for (let i = 16; i < 64; ++i) W[i] = (this.gamma1(W[i - 2]) + W[i - 7] + this.gamma0(W[i - 15]) + W[i - 16]) | 0

    for (let j = 0; j < 64; ++j) {
      const T1 = (h + this.sigma1(e) + this.ch(e, f, g) + K[j]! + W[j]) | 0
      const T2 = (this.sigma0(a) + this.maj(a, b, c)) | 0

      h = g
      g = f
      f = e
      e = (d + T1) | 0
      d = c
      c = b
      b = a
      a = (T1 + T2) | 0
    }

    this._a = (a + this._a) | 0
    this._b = (b + this._b) | 0
    this._c = (c + this._c) | 0
    this._d = (d + this._d) | 0
    this._e = (e + this._e) | 0
    this._f = (f + this._f) | 0
    this._g = (g + this._g) | 0
    this._h = (h + this._h) | 0
  }

  protected override _hash(): Uint8Array {
    const H = new Uint8Array(32)
    const view = new DataView(H.buffer)
    view.setInt32(0, this._a, false)
    view.setInt32(4, this._b, false)
    view.setInt32(8, this._c, false)
    view.setInt32(12, this._d, false)
    view.setInt32(16, this._e, false)
    view.setInt32(20, this._f, false)
    view.setInt32(24, this._g, false)
    view.setInt32(28, this._h, false)
    return H
 }

}
