// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/neighbors.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { ClientCredentials } from "./clients_pb.js";

/**
 * @generated from message avn.connect.v1.NeighborServer
 */
export class NeighborServer extends Message<NeighborServer> {
  /**
   * @generated from field: string client_id = 1;
   */
  clientId = "";

  /**
   * @generated from field: string wan_address = 2;
   */
  wanAddress = "";

  /**
   * @generated from field: int32 lan_port = 4;
   */
  lanPort = 0;

  /**
   * @generated from field: string client_name = 5;
   */
  clientName = "";

  /**
   * @generated from field: repeated string lan_hostnames = 6;
   */
  lanHostnames: string[] = [];

  constructor(data?: PartialMessage<NeighborServer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.NeighborServer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "wan_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "lan_port", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "client_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "lan_hostnames", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NeighborServer {
    return new NeighborServer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NeighborServer {
    return new NeighborServer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NeighborServer {
    return new NeighborServer().fromJsonString(jsonString, options);
  }

  static equals(a: NeighborServer | PlainMessage<NeighborServer> | undefined, b: NeighborServer | PlainMessage<NeighborServer> | undefined): boolean {
    return proto3.util.equals(NeighborServer, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.RegisterNeighborServerRequest
 */
export class RegisterNeighborServerRequest extends Message<RegisterNeighborServerRequest> {
  /**
   * @generated from field: avn.connect.v1.ClientCredentials client = 1;
   */
  client?: ClientCredentials;

  /**
   * @generated from field: string lan_address = 2;
   */
  lanAddress = "";

  /**
   * @generated from field: int32 lan_port = 3;
   */
  lanPort = 0;

  /**
   * @generated from field: repeated string lan_addresses = 4;
   */
  lanAddresses: string[] = [];

  /**
   * @generated from field: string client_name = 5;
   */
  clientName = "";

  constructor(data?: PartialMessage<RegisterNeighborServerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.RegisterNeighborServerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "client", kind: "message", T: ClientCredentials },
    { no: 2, name: "lan_address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lan_port", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "lan_addresses", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "client_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterNeighborServerRequest {
    return new RegisterNeighborServerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterNeighborServerRequest {
    return new RegisterNeighborServerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterNeighborServerRequest {
    return new RegisterNeighborServerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterNeighborServerRequest | PlainMessage<RegisterNeighborServerRequest> | undefined, b: RegisterNeighborServerRequest | PlainMessage<RegisterNeighborServerRequest> | undefined): boolean {
    return proto3.util.equals(RegisterNeighborServerRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.RegisterNeighborServerResponse
 */
export class RegisterNeighborServerResponse extends Message<RegisterNeighborServerResponse> {
  /**
   * @generated from field: avn.connect.v1.NeighborServer server = 1;
   */
  server?: NeighborServer;

  constructor(data?: PartialMessage<RegisterNeighborServerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.RegisterNeighborServerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "server", kind: "message", T: NeighborServer },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterNeighborServerResponse {
    return new RegisterNeighborServerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterNeighborServerResponse {
    return new RegisterNeighborServerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterNeighborServerResponse {
    return new RegisterNeighborServerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterNeighborServerResponse | PlainMessage<RegisterNeighborServerResponse> | undefined, b: RegisterNeighborServerResponse | PlainMessage<RegisterNeighborServerResponse> | undefined): boolean {
    return proto3.util.equals(RegisterNeighborServerResponse, a, b);
  }
}

