// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/presence.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum avn.connect.v1.PresenceState
 */
export enum PresenceState {
  /**
   * @generated from enum value: PRESENCE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PRESENCE_STATE_ADDED = 1;
   */
  ADDED = 1,

  /**
   * @generated from enum value: PRESENCE_STATE_REMOVED = 2;
   */
  REMOVED = 2,

  /**
   * @generated from enum value: PRESENCE_STATE_CHANGED = 3;
   */
  CHANGED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(PresenceState)
proto3.util.setEnumType(PresenceState, "avn.connect.v1.PresenceState", [
  { no: 0, name: "PRESENCE_STATE_UNSPECIFIED" },
  { no: 1, name: "PRESENCE_STATE_ADDED" },
  { no: 2, name: "PRESENCE_STATE_REMOVED" },
  { no: 3, name: "PRESENCE_STATE_CHANGED" },
]);

/**
 * @generated from message avn.connect.v1.PresenceUpdate
 */
export class PresenceUpdate extends Message<PresenceUpdate> {
  /**
   * @generated from field: avn.connect.v1.PresenceState state = 1;
   */
  state = PresenceState.UNSPECIFIED;

  /**
   * @generated from field: avn.connect.v1.PresenceInfo info = 2;
   */
  info?: PresenceInfo;

  constructor(data?: PartialMessage<PresenceUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.PresenceUpdate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(PresenceState) },
    { no: 2, name: "info", kind: "message", T: PresenceInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PresenceUpdate {
    return new PresenceUpdate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PresenceUpdate {
    return new PresenceUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PresenceUpdate {
    return new PresenceUpdate().fromJsonString(jsonString, options);
  }

  static equals(a: PresenceUpdate | PlainMessage<PresenceUpdate> | undefined, b: PresenceUpdate | PlainMessage<PresenceUpdate> | undefined): boolean {
    return proto3.util.equals(PresenceUpdate, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.PresenceInfo
 */
export class PresenceInfo extends Message<PresenceInfo> {
  /**
   * @generated from field: string connection_id = 1;
   */
  connectionId = "";

  /**
   * @generated from field: optional string session_id = 2;
   */
  sessionId?: string;

  /**
   * @generated from field: optional string room_id = 3;
   */
  roomId?: string;

  /**
   * @generated from field: optional string asset_id = 4;
   */
  assetId?: string;

  constructor(data?: PartialMessage<PresenceInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.PresenceInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "asset_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PresenceInfo {
    return new PresenceInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PresenceInfo {
    return new PresenceInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PresenceInfo {
    return new PresenceInfo().fromJsonString(jsonString, options);
  }

  static equals(a: PresenceInfo | PlainMessage<PresenceInfo> | undefined, b: PresenceInfo | PlainMessage<PresenceInfo> | undefined): boolean {
    return proto3.util.equals(PresenceInfo, a, b);
  }
}

