// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/user.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message avn.connect.v1.User
 */
export class User extends Message<User> {
  /**
   * @generated from field: int32 user_id = 1;
   */
  userId = 0;

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string country_id = 4;
   */
  countryId = "";

  /**
   * @generated from field: string language_id = 5;
   */
  languageId = "";

  /**
   * @generated from field: bool deleted = 6;
   */
  deleted = false;

  /**
   * More sensitive fields are optional based on permissions and availability
   *
   * @generated from field: optional bool email_verified = 10;
   */
  emailVerified?: boolean;

  /**
   * @generated from field: optional string picture_url = 11;
   */
  pictureUrl?: string;

  /**
   * @generated from field: optional string phone = 12;
   */
  phone?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp first_login = 13;
   */
  firstLogin?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp last_login = 14;
   */
  lastLogin?: Timestamp;

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "country_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "email_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 11, name: "picture_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "first_login", kind: "message", T: Timestamp, opt: true },
    { no: 14, name: "last_login", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

