// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/operations.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Generic operation result
 *
 * @generated from enum avn.connect.v1.OperationState
 */
export enum OperationState {
  /**
   * @generated from enum value: OPERATION_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: OPERATION_STATE_OPEN = 1;
   */
  OPEN = 1,

  /**
   * @generated from enum value: OPERATION_STATE_CLOSED = 2;
   */
  CLOSED = 2,

  /**
   * @generated from enum value: OPERATION_STATE_NOT_FOUND = 3;
   */
  NOT_FOUND = 3,

  /**
   * @generated from enum value: OPERATION_STATE_FORBIDDEN = 4;
   */
  FORBIDDEN = 4,

  /**
   * @generated from enum value: OPERATION_STATE_EXPIRED = 5;
   */
  EXPIRED = 5,

  /**
   * @generated from enum value: OPERATION_STATE_PROCESSING = 6;
   */
  PROCESSING = 6,

  /**
   * @generated from enum value: OPERATION_STATE_SUCCESS = 7;
   */
  SUCCESS = 7,

  /**
   * @generated from enum value: OPERATION_STATE_CANCELED = 8;
   */
  CANCELED = 8,

  /**
   * @generated from enum value: OPERATION_STATE_TOO_MANY_REQUESTS = 9;
   */
  TOO_MANY_REQUESTS = 9,

  /**
   * @generated from enum value: OPERATION_STATE_ERROR = 10;
   */
  ERROR = 10,

  /**
   * @generated from enum value: OPERATION_STATE_QUEUED = 11;
   */
  QUEUED = 11,

  /**
   * @generated from enum value: OPERATION_STATE_TRANSCODING = 12;
   */
  TRANSCODING = 12,

  /**
   * @generated from enum value: OPERATION_STATE_TRANSFERRING = 13;
   */
  TRANSFERRING = 13,

  /**
   * @generated from enum value: OPERATION_STATE_COMPLETE = 14;
   */
  COMPLETE = 14,

  /**
   * @generated from enum value: OPERATION_STATE_INITIALIZING = 15;
   */
  INITIALIZING = 15,
}
// Retrieve enum metadata with: proto3.getEnumType(OperationState)
proto3.util.setEnumType(OperationState, "avn.connect.v1.OperationState", [
  { no: 0, name: "OPERATION_STATE_UNSPECIFIED" },
  { no: 1, name: "OPERATION_STATE_OPEN" },
  { no: 2, name: "OPERATION_STATE_CLOSED" },
  { no: 3, name: "OPERATION_STATE_NOT_FOUND" },
  { no: 4, name: "OPERATION_STATE_FORBIDDEN" },
  { no: 5, name: "OPERATION_STATE_EXPIRED" },
  { no: 6, name: "OPERATION_STATE_PROCESSING" },
  { no: 7, name: "OPERATION_STATE_SUCCESS" },
  { no: 8, name: "OPERATION_STATE_CANCELED" },
  { no: 9, name: "OPERATION_STATE_TOO_MANY_REQUESTS" },
  { no: 10, name: "OPERATION_STATE_ERROR" },
  { no: 11, name: "OPERATION_STATE_QUEUED" },
  { no: 12, name: "OPERATION_STATE_TRANSCODING" },
  { no: 13, name: "OPERATION_STATE_TRANSFERRING" },
  { no: 14, name: "OPERATION_STATE_COMPLETE" },
  { no: 15, name: "OPERATION_STATE_INITIALIZING" },
]);

