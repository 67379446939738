// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/thinglink.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message avn.connect.v1.TransformMediaRequest
 */
export class TransformMediaRequest extends Message<TransformMediaRequest> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<TransformMediaRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.TransformMediaRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TransformMediaRequest {
    return new TransformMediaRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TransformMediaRequest {
    return new TransformMediaRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TransformMediaRequest {
    return new TransformMediaRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TransformMediaRequest | PlainMessage<TransformMediaRequest> | undefined, b: TransformMediaRequest | PlainMessage<TransformMediaRequest> | undefined): boolean {
    return proto3.util.equals(TransformMediaRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.TransformMediaResponse
 */
export class TransformMediaResponse extends Message<TransformMediaResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<TransformMediaResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.TransformMediaResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TransformMediaResponse {
    return new TransformMediaResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TransformMediaResponse {
    return new TransformMediaResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TransformMediaResponse {
    return new TransformMediaResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TransformMediaResponse | PlainMessage<TransformMediaResponse> | undefined, b: TransformMediaResponse | PlainMessage<TransformMediaResponse> | undefined): boolean {
    return proto3.util.equals(TransformMediaResponse, a, b);
  }
}

