// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/blockade.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GenerateSkyboxRequest, GenerateSkyboxResponse, GetSkyboxStyleFamiliesRequest, GetSkyboxStyleFamiliesResponse } from "./blockade_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.BlockadeService
 */
export const BlockadeService = {
  typeName: "avn.connect.v1.BlockadeService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.BlockadeService.GenerateSkybox
     */
    generateSkybox: {
      name: "GenerateSkybox",
      I: GenerateSkyboxRequest,
      O: GenerateSkyboxResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc avn.connect.v1.BlockadeService.GetSkyboxStyleFamilies
     */
    getSkyboxStyleFamilies: {
      name: "GetSkyboxStyleFamilies",
      I: GetSkyboxStyleFamiliesRequest,
      O: GetSkyboxStyleFamiliesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

