import { ConnectServices } from "./connect-services.js"

export const GenericMediaType =  "application/octet-stream"

let mediaTypesByExtensionCache : Map<string, string> | undefined = undefined
async function checkMediaTypesCache(ConnectServices: ConnectServices): Promise<Map<string, string>> {
    if(!mediaTypesByExtensionCache) {
        mediaTypesByExtensionCache = new Map()
        const cacheResult = await ConnectServices.Media.getMediaTypeExtensionMap({})
        for(let { extension, mediaType } of cacheResult.mediaTypes) {
            mediaTypesByExtensionCache.set(extension, mediaType)
        }
    }
    return mediaTypesByExtensionCache    
}

export async function guessMimeTypeForFile(ConnectServices: ConnectServices, file: File) : Promise<string | undefined> {
    if(file.type) return file.type
    const extension = file.name.split('.').pop()?.toLowerCase()
    const mediaTypesByExtension = await checkMediaTypesCache(ConnectServices)
    return extension ? mediaTypesByExtension.get(extension) : undefined
}

export async function guessMimeTypeForExtension(ConnectServices: ConnectServices, extension: string) : Promise<string | undefined> {
    const mediaTypesByExtension = await checkMediaTypesCache(ConnectServices)
    return mediaTypesByExtension.get(extension.toLowerCase())
}

const IconsByMediaType = new Map<string, URL>([
    ["application/octet-stream", new URL("https://avnfs.com/SCb98-2LwzEYHcBa2i3kLX8UtQyoog3XJEWZ3yORZ5Q?size=5011&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=application-bin.svg")],
    ["application/pdf", new URL("https://avnfs.com/bfH0yk2i5sFJ1h3LMW-bX9EksWUTzJ9aC6kQd0RuP38?size=4991&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=application-pdf.svg")],
    ["application/zip", new URL("https://avnfs.com/kkFmL3X83iChzSBE_2sjqJKgBINMzim6OrkQlmdMHdc?size=4501&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=application-zip.svg")],
    ["application", new URL("https://avnfs.com/Rbm4XgTxAk1Xg8cBVXbo-rGW3v9Wniu01biYYcJMFXs?size=2792&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=file.svg")],

    ["audio/mpeg3", new URL("https://avnfs.com/Lmiax_fo2gBXrF49DqjxXHF1t__pCidHmO1iqE-nOcM?size=6518&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=audio-mp3.svg")],
    ["audio/ogg", new URL("https://avnfs.com/2-V4i73sEOObj2MbBE70YuiHfZ5_g4TDM_QK7xQJoIA?size=6937&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=audio-ogg.svg")],
    ["audio", new URL("https://avnfs.com/W7rqEWK48nSgJVTEKV1l-VZWgbESOE212bfzmYQUa80?size=6417&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=audio.svg")],
    
    ["image/gif", new URL("https://avnfs.com/U_SJ1LzO1tZ-jWF12Ri7g4hAIP-KUSOx1E3pL3T2IV4?size=4874&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=image-gif.svg")],
    ["image/jpeg", new URL("https://avnfs.com/2MNHvfm22mT-JDyVRQs2so3a5nTwWAMQgUfBHIT2RRk?size=5794&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=image-jpeg.svg")],
    ["image/png", new URL("https://avnfs.com/DTQdQL3Mui2LqyuCPzcBQRC9FEa9UiLHqsINWU7TAfk?size=5330&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=image-png.svg")],
    ["image/svg+xml", new URL("https://avnfs.com/LcaCCSmv8PEyk_duY6rpjVvv4k03ava-rWS7RSqq31g?size=6012&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=image-svg.svg")],
    ["image", new URL("https://avnfs.com/8lwuK3v9_G4KdkZVes6A82loWwuumeRZijYENFy_FVk?size=5391&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=image.svg")],

    ["model/gltf-binary", new URL("https://avnfs.com/aZbrG3HJ7cNKhW01yiWu56FsXYSH_jsYOQsTS1nNxXg?size=6118&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=model-glb.svg")],
    ["model/gltf+json", new URL("https://avnfs.com/Hdx9mBN1D2y6D13vXNiUlB3-8U2srYlcl78uiSTaaAo?size=5368&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=model-gltf.svg")],
    ["model/stl", new URL("https://avnfs.com/N-3Hs2QeCeACBqetXGcMHj1JXchqTXjNutNilZYTyfQ?size=5589&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=model-stl.svg")],
    ["model", new URL("https://avnfs.com/9Rf_UT2FBqvMNh0kydi-m4fgpQohe_thZOE36W8lqfU?size=6225&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=model.svg")],

    ["text/html", new URL("https://avnfs.com/BNJIsBJ7t3AhkNbf7mGoVAZLf9Sm6vPqLjp1IpFaMlI?size=4395&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=text-html.svg")],
    ["text/markdown", new URL("https://avnfs.com/aFMo28RxC4kFmYokBIBtRZL8mphOd-TGwCmywkAYlAY?size=4657&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=text-markdown.svg")],
    ["text/plain", new URL("https://avnfs.com/1I6Tl9SgM5VIYoM0drcE85WufTxhttuQtam8SDyouKo?size=4653&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=text-plain.svg")],
    ["text", new URL("https://avnfs.com/CuVXmZhqO-0aP9hAH_WDMg4V0F9djJ2ZvWJ2mq374OE?size=4245&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=text.svg")],

    ["video/mp4", new URL("https://avnfs.com/X8gQyqeARhNjku_7PJW0sTMhfZ83sOXKcgaqhnMZlwQ?size=5033&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=video-mp4.svg")],
    ["video/mpeg", new URL("https://avnfs.com/fZRGnuwjemNXHQWOcYr9TFd37kK-80G089M8x5s6Gzw?size=5888&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=video-mpeg.svg")],
    ["video", new URL("https://avnfs.com/9pMAsT_bNdwUSvepJsvoHvcbTVVZ5mcv3Rfyewp6DD4?size=5659&type=image%2Fsvg%2Bxml%3Bproj%3Dflat&name=video.svg")],

])

const GenericIconForMediaType = IconsByMediaType.get("application/octet-stream")!

export function iconForMediaType(mediaType: string) : URL {
    let result = IconsByMediaType.get(mediaType)
    if(!result) {
        const genericType = mediaType.split("/")[0]
        if(genericType) {
            result = IconsByMediaType.get(genericType)
        }
    }
    return result || GenericIconForMediaType
}
