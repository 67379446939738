// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/cloud.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddCloudFilesRequest, AddCloudFilesResponse, GetCloudFilesRequest, GetCloudFilesResponse, GetCloudSummaryRequest, GetCloudSummaryResponse, RemoveCloudFilesRequest, SearchCloudFilesRequest, SearchCloudFilesResponse } from "./cloud_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";
import { AddTagsRequest, RemoveTagsRequest } from "./tags_pb.js";
import { SetEntityPropertiesRequest } from "./entities_pb.js";

/**
 * @generated from service avn.connect.v1.CloudService
 */
export const CloudService = {
  typeName: "avn.connect.v1.CloudService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.CloudService.GetCloudFiles
     */
    getCloudFiles: {
      name: "GetCloudFiles",
      I: GetCloudFilesRequest,
      O: GetCloudFilesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.CloudService.AddCloudFiles
     */
    addCloudFiles: {
      name: "AddCloudFiles",
      I: AddCloudFilesRequest,
      O: AddCloudFilesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.CloudService.RemoveCloudFiles
     */
    removeCloudFiles: {
      name: "RemoveCloudFiles",
      I: RemoveCloudFilesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.CloudService.AddTags
     */
    addTags: {
      name: "AddTags",
      I: AddTagsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.CloudService.RemoveTags
     */
    removeTags: {
      name: "RemoveTags",
      I: RemoveTagsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.CloudService.SearchCloudFiles
     */
    searchCloudFiles: {
      name: "SearchCloudFiles",
      I: SearchCloudFilesRequest,
      O: SearchCloudFilesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.CloudService.GetCloudSummary
     */
    getCloudSummary: {
      name: "GetCloudSummary",
      I: GetCloudSummaryRequest,
      O: GetCloudSummaryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Property management
     *
     * @generated from rpc avn.connect.v1.CloudService.SetProperties
     */
    setProperties: {
      name: "SetProperties",
      I: SetEntityPropertiesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

