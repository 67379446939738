// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/cloud.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Authorization } from "./authorization_pb.js";
import { TranscodeImageSpec } from "./media_pb.js";
import { OrderClause, TextSearch } from "./entities_pb.js";
import { TagFilter } from "./tags_pb.js";

/**
 * @generated from message avn.connect.v1.CloudFile
 */
export class CloudFile extends Message<CloudFile> {
  /**
   * @generated from field: int32 entity_id = 1;
   */
  entityId = 0;

  /**
   * Cloud files can be owned by users or organizations
   *
   * @generated from oneof avn.connect.v1.CloudFile.owner
   */
  owner: {
    /**
     * @generated from field: int32 organization_id = 2;
     */
    value: number;
    case: "organizationId";
  } | {
    /**
     * @generated from field: int32 user_id = 3;
     */
    value: number;
    case: "userId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * AVNFS URL
   *
   * @generated from field: string file_url = 4;
   */
  fileUrl = "";

  /**
   * @generated from field: google.protobuf.Timestamp updated = 5;
   */
  updated?: Timestamp;

  /**
   * @generated from field: repeated int32 tags = 6;
   */
  tags: number[] = [];

  /**
   * In future this should be based on an image request spec in the request
   *
   * @generated from field: string preview_url = 7;
   */
  previewUrl = "";

  /**
   * CLOUD_FILE_LEGACY_ID
   *
   * @generated from field: string legacy_id = 10;
   */
  legacyId = "";

  /**
   * Legacy fields
   *
   * @generated from field: optional string user_name = 11;
   */
  userName?: string;

  /**
   * @generated from field: optional string device_name = 12;
   */
  deviceName?: string;

  /**
   * @generated from field: optional int32 place_id = 13;
   */
  placeId?: number;

  /**
   * @generated from field: optional string file_name = 14;
   */
  fileName?: string;

  /**
   * @generated from field: optional string media_type = 15;
   */
  mediaType?: string;

  /**
   * @generated from field: optional int64 size_bytes = 16;
   */
  sizeBytes?: bigint;

  constructor(data?: PartialMessage<CloudFile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CloudFile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "owner" },
    { no: 3, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "owner" },
    { no: 4, name: "file_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updated", kind: "message", T: Timestamp },
    { no: 6, name: "tags", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 7, name: "preview_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "legacy_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "device_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "place_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "media_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "size_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CloudFile {
    return new CloudFile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CloudFile {
    return new CloudFile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CloudFile {
    return new CloudFile().fromJsonString(jsonString, options);
  }

  static equals(a: CloudFile | PlainMessage<CloudFile> | undefined, b: CloudFile | PlainMessage<CloudFile> | undefined): boolean {
    return proto3.util.equals(CloudFile, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetCloudFilesRequest
 */
export class GetCloudFilesRequest extends Message<GetCloudFilesRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: repeated int32 entity_ids = 2;
   */
  entityIds: number[] = [];

  /**
   * @generated from field: avn.connect.v1.TranscodeImageSpec preview_spec = 3;
   */
  previewSpec?: TranscodeImageSpec;

  /**
   * @generated from field: repeated string legacy_ids = 10;
   */
  legacyIds: string[] = [];

  constructor(data?: PartialMessage<GetCloudFilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetCloudFilesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "entity_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 3, name: "preview_spec", kind: "message", T: TranscodeImageSpec },
    { no: 10, name: "legacy_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCloudFilesRequest {
    return new GetCloudFilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCloudFilesRequest {
    return new GetCloudFilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCloudFilesRequest {
    return new GetCloudFilesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCloudFilesRequest | PlainMessage<GetCloudFilesRequest> | undefined, b: GetCloudFilesRequest | PlainMessage<GetCloudFilesRequest> | undefined): boolean {
    return proto3.util.equals(GetCloudFilesRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetCloudFilesResponse
 */
export class GetCloudFilesResponse extends Message<GetCloudFilesResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.CloudFile results = 1;
   */
  results: CloudFile[] = [];

  constructor(data?: PartialMessage<GetCloudFilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetCloudFilesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "message", T: CloudFile, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCloudFilesResponse {
    return new GetCloudFilesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCloudFilesResponse {
    return new GetCloudFilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCloudFilesResponse {
    return new GetCloudFilesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCloudFilesResponse | PlainMessage<GetCloudFilesResponse> | undefined, b: GetCloudFilesResponse | PlainMessage<GetCloudFilesResponse> | undefined): boolean {
    return proto3.util.equals(GetCloudFilesResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.AddCloudFilesRequest
 */
export class AddCloudFilesRequest extends Message<AddCloudFilesRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from oneof avn.connect.v1.AddCloudFilesRequest.owner
   */
  owner: {
    /**
     * @generated from field: int32 organization_id = 2;
     */
    value: number;
    case: "organizationId";
  } | {
    /**
     * @generated from field: int32 user_id = 3;
     */
    value: number;
    case: "userId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: repeated string file_urls = 4;
   */
  fileUrls: string[] = [];

  constructor(data?: PartialMessage<AddCloudFilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.AddCloudFilesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "owner" },
    { no: 3, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "owner" },
    { no: 4, name: "file_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddCloudFilesRequest {
    return new AddCloudFilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddCloudFilesRequest {
    return new AddCloudFilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddCloudFilesRequest {
    return new AddCloudFilesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddCloudFilesRequest | PlainMessage<AddCloudFilesRequest> | undefined, b: AddCloudFilesRequest | PlainMessage<AddCloudFilesRequest> | undefined): boolean {
    return proto3.util.equals(AddCloudFilesRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.AddCloudFilesResponse
 */
export class AddCloudFilesResponse extends Message<AddCloudFilesResponse> {
  /**
   * @generated from field: repeated int32 entity_ids = 1;
   */
  entityIds: number[] = [];

  /**
   * CLOUD_FILE_LEGACY_ID
   *
   * @generated from field: repeated string legacy_ids = 10;
   */
  legacyIds: string[] = [];

  constructor(data?: PartialMessage<AddCloudFilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.AddCloudFilesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 10, name: "legacy_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddCloudFilesResponse {
    return new AddCloudFilesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddCloudFilesResponse {
    return new AddCloudFilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddCloudFilesResponse {
    return new AddCloudFilesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddCloudFilesResponse | PlainMessage<AddCloudFilesResponse> | undefined, b: AddCloudFilesResponse | PlainMessage<AddCloudFilesResponse> | undefined): boolean {
    return proto3.util.equals(AddCloudFilesResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.RemoveCloudFilesRequest
 */
export class RemoveCloudFilesRequest extends Message<RemoveCloudFilesRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: repeated int32 entity_ids = 2;
   */
  entityIds: number[] = [];

  /**
   * @generated from field: repeated string legacy_ids = 10;
   */
  legacyIds: string[] = [];

  constructor(data?: PartialMessage<RemoveCloudFilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.RemoveCloudFilesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "entity_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 10, name: "legacy_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveCloudFilesRequest {
    return new RemoveCloudFilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveCloudFilesRequest {
    return new RemoveCloudFilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveCloudFilesRequest {
    return new RemoveCloudFilesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveCloudFilesRequest | PlainMessage<RemoveCloudFilesRequest> | undefined, b: RemoveCloudFilesRequest | PlainMessage<RemoveCloudFilesRequest> | undefined): boolean {
    return proto3.util.equals(RemoveCloudFilesRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.SearchCloudFilesRequest
 */
export class SearchCloudFilesRequest extends Message<SearchCloudFilesRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from oneof avn.connect.v1.SearchCloudFilesRequest.owner
   */
  owner: {
    /**
     * @generated from field: int32 organization_id = 2;
     */
    value: number;
    case: "organizationId";
  } | {
    /**
     * @generated from field: int32 user_id = 3;
     */
    value: number;
    case: "userId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * Search text fields
   *
   * @generated from field: optional avn.connect.v1.TextSearch text_search = 4;
   */
  textSearch?: TextSearch;

  /**
   * Not implemented yet
   *
   * @generated from field: repeated avn.connect.v1.TagFilter tag_filters = 5;
   */
  tagFilters: TagFilter[] = [];

  /**
   * @generated from field: repeated string filter_media_types = 6;
   */
  filterMediaTypes: string[] = [];

  /**
   * @generated from field: optional google.protobuf.Timestamp after = 7;
   */
  after?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp before = 8;
   */
  before?: Timestamp;

  /**
   * Clauses to order the results by
   *
   * @generated from field: repeated avn.connect.v1.OrderClause order_by = 9;
   */
  orderBy: OrderClause[] = [];

  /**
   * @generated from field: optional int32 page_size = 11;
   */
  pageSize?: number;

  /**
   * @generated from field: optional string page_token = 12;
   */
  pageToken?: string;

  /**
   * @generated from field: avn.connect.v1.TranscodeImageSpec preview_spec = 13;
   */
  previewSpec?: TranscodeImageSpec;

  constructor(data?: PartialMessage<SearchCloudFilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.SearchCloudFilesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "owner" },
    { no: 3, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "owner" },
    { no: 4, name: "text_search", kind: "message", T: TextSearch, opt: true },
    { no: 5, name: "tag_filters", kind: "message", T: TagFilter, repeated: true },
    { no: 6, name: "filter_media_types", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "after", kind: "message", T: Timestamp, opt: true },
    { no: 8, name: "before", kind: "message", T: Timestamp, opt: true },
    { no: 9, name: "order_by", kind: "message", T: OrderClause, repeated: true },
    { no: 11, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 12, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "preview_spec", kind: "message", T: TranscodeImageSpec },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchCloudFilesRequest {
    return new SearchCloudFilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchCloudFilesRequest {
    return new SearchCloudFilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchCloudFilesRequest {
    return new SearchCloudFilesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SearchCloudFilesRequest | PlainMessage<SearchCloudFilesRequest> | undefined, b: SearchCloudFilesRequest | PlainMessage<SearchCloudFilesRequest> | undefined): boolean {
    return proto3.util.equals(SearchCloudFilesRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.SearchCloudFilesResponse
 */
export class SearchCloudFilesResponse extends Message<SearchCloudFilesResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.CloudFile results = 1;
   */
  results: CloudFile[] = [];

  /**
   * @generated from field: optional string next_page_token = 2;
   */
  nextPageToken?: string;

  constructor(data?: PartialMessage<SearchCloudFilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.SearchCloudFilesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "message", T: CloudFile, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchCloudFilesResponse {
    return new SearchCloudFilesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchCloudFilesResponse {
    return new SearchCloudFilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchCloudFilesResponse {
    return new SearchCloudFilesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SearchCloudFilesResponse | PlainMessage<SearchCloudFilesResponse> | undefined, b: SearchCloudFilesResponse | PlainMessage<SearchCloudFilesResponse> | undefined): boolean {
    return proto3.util.equals(SearchCloudFilesResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetCloudSummaryRequest
 */
export class GetCloudSummaryRequest extends Message<GetCloudSummaryRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from oneof avn.connect.v1.GetCloudSummaryRequest.owner
   */
  owner: {
    /**
     * @generated from field: int32 organization_id = 2;
     */
    value: number;
    case: "organizationId";
  } | {
    /**
     * @generated from field: int32 user_id = 3;
     */
    value: number;
    case: "userId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GetCloudSummaryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetCloudSummaryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "owner" },
    { no: 3, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "owner" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCloudSummaryRequest {
    return new GetCloudSummaryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCloudSummaryRequest {
    return new GetCloudSummaryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCloudSummaryRequest {
    return new GetCloudSummaryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCloudSummaryRequest | PlainMessage<GetCloudSummaryRequest> | undefined, b: GetCloudSummaryRequest | PlainMessage<GetCloudSummaryRequest> | undefined): boolean {
    return proto3.util.equals(GetCloudSummaryRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetCloudSummaryResponse
 */
export class GetCloudSummaryResponse extends Message<GetCloudSummaryResponse> {
  /**
   * @generated from field: int32 total_count = 1;
   */
  totalCount = 0;

  /**
   * @generated from field: int64 total_bytes = 2;
   */
  totalBytes = protoInt64.zero;

  /**
   * @generated from field: int64 capacity_bytes = 3;
   */
  capacityBytes = protoInt64.zero;

  /**
   * @generated from field: optional google.protobuf.Timestamp oldest = 4;
   */
  oldest?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp newest = 5;
   */
  newest?: Timestamp;

  constructor(data?: PartialMessage<GetCloudSummaryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetCloudSummaryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "total_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "capacity_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "oldest", kind: "message", T: Timestamp, opt: true },
    { no: 5, name: "newest", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCloudSummaryResponse {
    return new GetCloudSummaryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCloudSummaryResponse {
    return new GetCloudSummaryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCloudSummaryResponse {
    return new GetCloudSummaryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCloudSummaryResponse | PlainMessage<GetCloudSummaryResponse> | undefined, b: GetCloudSummaryResponse | PlainMessage<GetCloudSummaryResponse> | undefined): boolean {
    return proto3.util.equals(GetCloudSummaryResponse, a, b);
  }
}

