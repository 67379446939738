// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/partners.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message avn.connect.v1.PartnerSession
 */
export class PartnerSession extends Message<PartnerSession> {
  /**
   * @generated from field: string partner_id = 1;
   */
  partnerId = "";

  /**
   * @generated from field: string session_id = 2;
   */
  sessionId = "";

  constructor(data?: PartialMessage<PartnerSession>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.PartnerSession";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "partner_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PartnerSession {
    return new PartnerSession().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PartnerSession {
    return new PartnerSession().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PartnerSession {
    return new PartnerSession().fromJsonString(jsonString, options);
  }

  static equals(a: PartnerSession | PlainMessage<PartnerSession> | undefined, b: PartnerSession | PlainMessage<PartnerSession> | undefined): boolean {
    return proto3.util.equals(PartnerSession, a, b);
  }
}

