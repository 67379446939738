// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/avnfs.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Authorization } from "./authorization_pb.js";
import { HeaderField } from "./http_pb.js";

/**
 * @generated from enum avn.connect.v1.AltServerType
 */
export enum AltServerType {
  /**
   * @generated from enum value: ALT_SERVER_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ALT_SERVER_TYPE_LAN = 1;
   */
  LAN = 1,

  /**
   * @generated from enum value: ALT_SERVER_TYPE_WAN = 2;
   */
  WAN = 2,

  /**
   * @generated from enum value: ALT_SERVER_TYPE_REGIONAL = 3;
   */
  REGIONAL = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AltServerType)
proto3.util.setEnumType(AltServerType, "avn.connect.v1.AltServerType", [
  { no: 0, name: "ALT_SERVER_TYPE_UNSPECIFIED" },
  { no: 1, name: "ALT_SERVER_TYPE_LAN" },
  { no: 2, name: "ALT_SERVER_TYPE_WAN" },
  { no: 3, name: "ALT_SERVER_TYPE_REGIONAL" },
]);

/**
 * @generated from message avn.connect.v1.GetFileUrlRequest
 */
export class GetFileUrlRequest extends Message<GetFileUrlRequest> {
  /**
   * base64url encoded SHA256 hash of the file contents
   *
   * @generated from field: string hash = 1;
   */
  hash = "";

  /**
   * size in bytes
   *
   * @generated from field: int64 size_bytes = 2;
   */
  sizeBytes = protoInt64.zero;

  /**
   * media type
   *
   * @generated from field: string media_type = 3;
   */
  mediaType = "";

  /**
   * filename (optional and only modifies the returned URL)
   *
   * @generated from field: optional string file_name = 4;
   */
  fileName?: string;

  constructor(data?: PartialMessage<GetFileUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetFileUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "size_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "media_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFileUrlRequest {
    return new GetFileUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFileUrlRequest {
    return new GetFileUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFileUrlRequest {
    return new GetFileUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetFileUrlRequest | PlainMessage<GetFileUrlRequest> | undefined, b: GetFileUrlRequest | PlainMessage<GetFileUrlRequest> | undefined): boolean {
    return proto3.util.equals(GetFileUrlRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetFileUrlResponse
 */
export class GetFileUrlResponse extends Message<GetFileUrlResponse> {
  /**
   * Only set if the file already exists
   *
   * @generated from field: optional string url = 1;
   */
  url?: string;

  constructor(data?: PartialMessage<GetFileUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetFileUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFileUrlResponse {
    return new GetFileUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFileUrlResponse {
    return new GetFileUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFileUrlResponse {
    return new GetFileUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetFileUrlResponse | PlainMessage<GetFileUrlResponse> | undefined, b: GetFileUrlResponse | PlainMessage<GetFileUrlResponse> | undefined): boolean {
    return proto3.util.equals(GetFileUrlResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.ImportUrlRequest
 */
export class ImportUrlRequest extends Message<ImportUrlRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * The remote server URL to import
   *
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * Override for the media type the remote server returns
   *
   * @generated from field: optional string media_type = 3;
   */
  mediaType?: string;

  /**
   * Additional headers to pass through to the remote server
   *
   * @generated from field: map<string, string> headers = 4;
   */
  headers: { [key: string]: string } = {};

  constructor(data?: PartialMessage<ImportUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.ImportUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "media_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "headers", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportUrlRequest {
    return new ImportUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportUrlRequest {
    return new ImportUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportUrlRequest {
    return new ImportUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ImportUrlRequest | PlainMessage<ImportUrlRequest> | undefined, b: ImportUrlRequest | PlainMessage<ImportUrlRequest> | undefined): boolean {
    return proto3.util.equals(ImportUrlRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.ImportUrlResponse
 */
export class ImportUrlResponse extends Message<ImportUrlResponse> {
  /**
   * The AVNFS URL of the imported file
   *
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<ImportUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.ImportUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportUrlResponse {
    return new ImportUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportUrlResponse {
    return new ImportUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportUrlResponse {
    return new ImportUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ImportUrlResponse | PlainMessage<ImportUrlResponse> | undefined, b: ImportUrlResponse | PlainMessage<ImportUrlResponse> | undefined): boolean {
    return proto3.util.equals(ImportUrlResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetManifestRequest
 */
export class GetManifestRequest extends Message<GetManifestRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * base64url encoded SHA256 hash of the file contents
   *
   * @generated from field: string hash = 2;
   */
  hash = "";

  /**
   * size in bytes for checking against allowances
   *
   * @generated from field: int64 size_bytes = 3;
   */
  sizeBytes = protoInt64.zero;

  /**
   * media types are expected to be overriden in the reference URL, but this might be useful for post-processing actions
   *
   * @generated from field: string media_type = 4;
   */
  mediaType = "";

  /**
   * filename (optional and only modifies the returned URL)
   *
   * @generated from field: optional string file_name = 5;
   */
  fileName?: string;

  constructor(data?: PartialMessage<GetManifestRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetManifestRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "size_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "media_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "file_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetManifestRequest {
    return new GetManifestRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetManifestRequest {
    return new GetManifestRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetManifestRequest {
    return new GetManifestRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetManifestRequest | PlainMessage<GetManifestRequest> | undefined, b: GetManifestRequest | PlainMessage<GetManifestRequest> | undefined): boolean {
    return proto3.util.equals(GetManifestRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.UploadManifest
 */
export class UploadManifest extends Message<UploadManifest> {
  /**
   * URL to upload to
   *
   * @generated from field: string upload_url = 1;
   */
  uploadUrl = "";

  /**
   * Eventual download URL
   *
   * @generated from field: string download_url = 2;
   */
  downloadUrl = "";

  /**
   * Additional fields to add to a POST
   *
   * @generated from field: repeated avn.connect.v1.HeaderField header_fields = 3;
   */
  headerFields: HeaderField[] = [];

  constructor(data?: PartialMessage<UploadManifest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.UploadManifest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "upload_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "download_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "header_fields", kind: "message", T: HeaderField, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadManifest {
    return new UploadManifest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadManifest {
    return new UploadManifest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadManifest {
    return new UploadManifest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadManifest | PlainMessage<UploadManifest> | undefined, b: UploadManifest | PlainMessage<UploadManifest> | undefined): boolean {
    return proto3.util.equals(UploadManifest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.AltServer
 */
export class AltServer extends Message<AltServer> {
  /**
   * @generated from field: avn.connect.v1.AltServerType type = 1;
   */
  type = AltServerType.UNSPECIFIED;

  /**
   * @generated from field: string client_name = 2;
   */
  clientName = "";

  /**
   * @generated from field: string client_id = 3;
   */
  clientId = "";

  /**
   * @generated from field: repeated string hosts = 4;
   */
  hosts: string[] = [];

  constructor(data?: PartialMessage<AltServer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.AltServer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(AltServerType) },
    { no: 2, name: "client_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "hosts", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AltServer {
    return new AltServer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AltServer {
    return new AltServer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AltServer {
    return new AltServer().fromJsonString(jsonString, options);
  }

  static equals(a: AltServer | PlainMessage<AltServer> | undefined, b: AltServer | PlainMessage<AltServer> | undefined): boolean {
    return proto3.util.equals(AltServer, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetAltServersRequest
 */
export class GetAltServersRequest extends Message<GetAltServersRequest> {
  constructor(data?: PartialMessage<GetAltServersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetAltServersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAltServersRequest {
    return new GetAltServersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAltServersRequest {
    return new GetAltServersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAltServersRequest {
    return new GetAltServersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAltServersRequest | PlainMessage<GetAltServersRequest> | undefined, b: GetAltServersRequest | PlainMessage<GetAltServersRequest> | undefined): boolean {
    return proto3.util.equals(GetAltServersRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetAltServersResponse
 */
export class GetAltServersResponse extends Message<GetAltServersResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.AltServer servers = 1;
   */
  servers: AltServer[] = [];

  constructor(data?: PartialMessage<GetAltServersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetAltServersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "servers", kind: "message", T: AltServer, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAltServersResponse {
    return new GetAltServersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAltServersResponse {
    return new GetAltServersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAltServersResponse {
    return new GetAltServersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAltServersResponse | PlainMessage<GetAltServersResponse> | undefined, b: GetAltServersResponse | PlainMessage<GetAltServersResponse> | undefined): boolean {
    return proto3.util.equals(GetAltServersResponse, a, b);
  }
}

