// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/licenses.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { PartnerSession } from "./partners_pb.js";
import { Authorization } from "./authorization_pb.js";

/**
 * @generated from message avn.connect.v1.LicenseInfo
 */
export class LicenseInfo extends Message<LicenseInfo> {
  /**
   * @generated from field: optional string license_id = 1;
   */
  licenseId?: string;

  /**
   * @generated from field: repeated string plan_codes = 2;
   */
  planCodes: string[] = [];

  /**
   * @generated from oneof avn.connect.v1.LicenseInfo.source
   */
  source: {
    /**
     * Client license
     *
     * @generated from field: string client_id = 3;
     */
    value: string;
    case: "clientId";
  } | {
    /**
     * Licenses that are allocated to the user directly (currently only via Zoho email matching) 
     *
     * @generated from field: int32 user_id = 4;
     */
    value: number;
    case: "userId";
  } | {
    /**
     * Organization assigned license (will be the ID of the licensee organization, not the channel library organization)
     *
     * @generated from field: int32 organization_id = 5;
     */
    value: number;
    case: "organizationId";
  } | {
    /**
     * Partner allocated license
     *
     * @generated from field: avn.connect.v1.PartnerSession partner_session = 6;
     */
    value: PartnerSession;
    case: "partnerSession";
  } | {
    /**
     * Dimensions created by authenticated users have additional privileges and allow access to user playlist activities
     *
     * @generated from field: int32 authenticated_user_id = 7;
     */
    value: number;
    case: "authenticatedUserId";
  } | {
    /**
     * Hall pass license apply to content only
     *
     * @generated from field: string pass_id = 8;
     */
    value: string;
    case: "passId";
  } | {
    /**
     * Dimension context (anything relating to how the dimension was created, e.g. 'common' plan codes that apply to all dimensions)
     *
     * @generated from field: string context = 9;
     */
    value: string;
    case: "context";
  } | {
    /**
     * Additional organization to license, currently passed through from ClassVR Player
     *
     * @generated from field: int32 user_organization_id = 10;
     */
    value: number;
    case: "userOrganizationId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: optional google.protobuf.Timestamp expires = 20;
   */
  expires?: Timestamp;

  constructor(data?: PartialMessage<LicenseInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.LicenseInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "license_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "plan_codes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "source" },
    { no: 4, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "source" },
    { no: 5, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "source" },
    { no: 6, name: "partner_session", kind: "message", T: PartnerSession, oneof: "source" },
    { no: 7, name: "authenticated_user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "source" },
    { no: 8, name: "pass_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "source" },
    { no: 9, name: "context", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "source" },
    { no: 10, name: "user_organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "source" },
    { no: 20, name: "expires", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LicenseInfo {
    return new LicenseInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LicenseInfo {
    return new LicenseInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LicenseInfo {
    return new LicenseInfo().fromJsonString(jsonString, options);
  }

  static equals(a: LicenseInfo | PlainMessage<LicenseInfo> | undefined, b: LicenseInfo | PlainMessage<LicenseInfo> | undefined): boolean {
    return proto3.util.equals(LicenseInfo, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetUserLicensesRequest
 */
export class GetUserLicensesRequest extends Message<GetUserLicensesRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 user_id = 2;
   */
  userId = 0;

  constructor(data?: PartialMessage<GetUserLicensesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetUserLicensesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserLicensesRequest {
    return new GetUserLicensesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserLicensesRequest {
    return new GetUserLicensesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserLicensesRequest {
    return new GetUserLicensesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserLicensesRequest | PlainMessage<GetUserLicensesRequest> | undefined, b: GetUserLicensesRequest | PlainMessage<GetUserLicensesRequest> | undefined): boolean {
    return proto3.util.equals(GetUserLicensesRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetUserLicensesResponse
 */
export class GetUserLicensesResponse extends Message<GetUserLicensesResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.LicenseInfo licenses = 1;
   */
  licenses: LicenseInfo[] = [];

  constructor(data?: PartialMessage<GetUserLicensesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetUserLicensesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "licenses", kind: "message", T: LicenseInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserLicensesResponse {
    return new GetUserLicensesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserLicensesResponse {
    return new GetUserLicensesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserLicensesResponse {
    return new GetUserLicensesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserLicensesResponse | PlainMessage<GetUserLicensesResponse> | undefined, b: GetUserLicensesResponse | PlainMessage<GetUserLicensesResponse> | undefined): boolean {
    return proto3.util.equals(GetUserLicensesResponse, a, b);
  }
}

