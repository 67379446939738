// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/activities.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CopyEntityRequest, CreateEntityResponse, DeleteEntityRequest, EntityInfoListRequest, EntityInfoListResponse, GetEntityRequest, GetMetadataResponse, MetadataRequest, SetEntityPropertiesRequest } from "./entities_pb.js";
import { Activity, AddActivityFilesRequest, CreateActivityRequest, RemoveActivityFilesRequest } from "./activities_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";
import { AddTagsRequest, RemoveTagsRequest } from "./tags_pb.js";

/**
 * @generated from service avn.connect.v1.ActivityService
 */
export const ActivityService = {
  typeName: "avn.connect.v1.ActivityService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.ActivityService.GetActivity
     */
    getActivity: {
      name: "GetActivity",
      I: GetEntityRequest,
      O: Activity,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.ActivityService.CreateActivity
     */
    createActivity: {
      name: "CreateActivity",
      I: CreateActivityRequest,
      O: CreateEntityResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.ActivityService.DeleteActivity
     */
    deleteActivity: {
      name: "DeleteActivity",
      I: DeleteEntityRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.ActivityService.CopyActivity
     */
    copyActivity: {
      name: "CopyActivity",
      I: CopyEntityRequest,
      O: CreateEntityResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get organization activities
     *
     * @generated from rpc avn.connect.v1.ActivityService.GetOrganizationActivities
     */
    getOrganizationActivities: {
      name: "GetOrganizationActivities",
      I: EntityInfoListRequest,
      O: EntityInfoListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get user activities
     *
     * @generated from rpc avn.connect.v1.ActivityService.GetUserActivities
     */
    getUserActivities: {
      name: "GetUserActivities",
      I: EntityInfoListRequest,
      O: EntityInfoListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Activity file management
     *
     * @generated from rpc avn.connect.v1.ActivityService.AddFiles
     */
    addFiles: {
      name: "AddFiles",
      I: AddActivityFilesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.ActivityService.RemoveFiles
     */
    removeFiles: {
      name: "RemoveFiles",
      I: RemoveActivityFilesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Tag management
     *
     * @generated from rpc avn.connect.v1.ActivityService.AddTags
     */
    addTags: {
      name: "AddTags",
      I: AddTagsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.ActivityService.RemoveTags
     */
    removeTags: {
      name: "RemoveTags",
      I: RemoveTagsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Key-value pair metadata per activity
     *
     * @generated from rpc avn.connect.v1.ActivityService.GetMetadata
     */
    getMetadata: {
      name: "GetMetadata",
      I: MetadataRequest,
      O: GetMetadataResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.ActivityService.SetMetadata
     */
    setMetadata: {
      name: "SetMetadata",
      I: MetadataRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Property management
     *
     * @generated from rpc avn.connect.v1.ActivityService.SetProperties
     */
    setProperties: {
      name: "SetProperties",
      I: SetEntityPropertiesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

