// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/roles.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message avn.connect.v1.Role
 */
export class Role extends Message<Role> {
  /**
   * @generated from field: int32 role_id = 1;
   */
  roleId = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: int32 level = 3;
   */
  level = 0;

  constructor(data?: PartialMessage<Role>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Role";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "level", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Role {
    return new Role().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Role {
    return new Role().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Role {
    return new Role().fromJsonString(jsonString, options);
  }

  static equals(a: Role | PlainMessage<Role> | undefined, b: Role | PlainMessage<Role> | undefined): boolean {
    return proto3.util.equals(Role, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetRolesRequest
 */
export class GetRolesRequest extends Message<GetRolesRequest> {
  constructor(data?: PartialMessage<GetRolesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetRolesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRolesRequest {
    return new GetRolesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRolesRequest {
    return new GetRolesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRolesRequest {
    return new GetRolesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRolesRequest | PlainMessage<GetRolesRequest> | undefined, b: GetRolesRequest | PlainMessage<GetRolesRequest> | undefined): boolean {
    return proto3.util.equals(GetRolesRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetRolesResponse
 */
export class GetRolesResponse extends Message<GetRolesResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.Role roles = 1;
   */
  roles: Role[] = [];

  constructor(data?: PartialMessage<GetRolesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetRolesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "roles", kind: "message", T: Role, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRolesResponse {
    return new GetRolesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRolesResponse {
    return new GetRolesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRolesResponse {
    return new GetRolesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRolesResponse | PlainMessage<GetRolesResponse> | undefined, b: GetRolesResponse | PlainMessage<GetRolesResponse> | undefined): boolean {
    return proto3.util.equals(GetRolesResponse, a, b);
  }
}

