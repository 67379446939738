// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/content.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Authorization } from "./authorization_pb.js";
import { EntityInfo } from "./entities_pb.js";

/**
 * @generated from message avn.connect.v1.MatchActivityFromCloudRequest
 */
export class MatchActivityFromCloudRequest extends Message<MatchActivityFromCloudRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 entity_id = 2;
   */
  entityId = 0;

  /**
   * Optional override for the org to assign
   *
   * @generated from field: optional int32 organization_id = 3;
   */
  organizationId?: number;

  constructor(data?: PartialMessage<MatchActivityFromCloudRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.MatchActivityFromCloudRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MatchActivityFromCloudRequest {
    return new MatchActivityFromCloudRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MatchActivityFromCloudRequest {
    return new MatchActivityFromCloudRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MatchActivityFromCloudRequest {
    return new MatchActivityFromCloudRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MatchActivityFromCloudRequest | PlainMessage<MatchActivityFromCloudRequest> | undefined, b: MatchActivityFromCloudRequest | PlainMessage<MatchActivityFromCloudRequest> | undefined): boolean {
    return proto3.util.equals(MatchActivityFromCloudRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.MatchActivityFromFilesRequest
 */
export class MatchActivityFromFilesRequest extends Message<MatchActivityFromFilesRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string icon_url = 3;
   */
  iconUrl = "";

  /**
   * @generated from field: repeated int32 tags = 4;
   */
  tags: number[] = [];

  /**
   * @generated from field: repeated string file_urls = 5;
   */
  fileUrls: string[] = [];

  /**
   * Optional override for the org to assign
   *
   * @generated from field: optional int32 organization_id = 6;
   */
  organizationId?: number;

  constructor(data?: PartialMessage<MatchActivityFromFilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.MatchActivityFromFilesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tags", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 5, name: "file_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MatchActivityFromFilesRequest {
    return new MatchActivityFromFilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MatchActivityFromFilesRequest {
    return new MatchActivityFromFilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MatchActivityFromFilesRequest {
    return new MatchActivityFromFilesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MatchActivityFromFilesRequest | PlainMessage<MatchActivityFromFilesRequest> | undefined, b: MatchActivityFromFilesRequest | PlainMessage<MatchActivityFromFilesRequest> | undefined): boolean {
    return proto3.util.equals(MatchActivityFromFilesRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.MatchActivityFromUrlRequest
 */
export class MatchActivityFromUrlRequest extends Message<MatchActivityFromUrlRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string icon_url = 3;
   */
  iconUrl = "";

  /**
   * @generated from field: repeated int32 tags = 4;
   */
  tags: number[] = [];

  /**
   * @generated from field: string url = 5;
   */
  url = "";

  /**
   * @generated from field: optional string context = 6;
   */
  context?: string;

  /**
   * Optional override for the org to assign
   *
   * @generated from field: optional int32 organization_id = 7;
   */
  organizationId?: number;

  constructor(data?: PartialMessage<MatchActivityFromUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.MatchActivityFromUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tags", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 5, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "context", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MatchActivityFromUrlRequest {
    return new MatchActivityFromUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MatchActivityFromUrlRequest {
    return new MatchActivityFromUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MatchActivityFromUrlRequest {
    return new MatchActivityFromUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MatchActivityFromUrlRequest | PlainMessage<MatchActivityFromUrlRequest> | undefined, b: MatchActivityFromUrlRequest | PlainMessage<MatchActivityFromUrlRequest> | undefined): boolean {
    return proto3.util.equals(MatchActivityFromUrlRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.MatchActivityResponse
 */
export class MatchActivityResponse extends Message<MatchActivityResponse> {
  /**
   * @generated from field: avn.connect.v1.EntityInfo activity = 1;
   */
  activity?: EntityInfo;

  constructor(data?: PartialMessage<MatchActivityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.MatchActivityResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "activity", kind: "message", T: EntityInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MatchActivityResponse {
    return new MatchActivityResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MatchActivityResponse {
    return new MatchActivityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MatchActivityResponse {
    return new MatchActivityResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MatchActivityResponse | PlainMessage<MatchActivityResponse> | undefined, b: MatchActivityResponse | PlainMessage<MatchActivityResponse> | undefined): boolean {
    return proto3.util.equals(MatchActivityResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.SubmitCommunityCategoryRequest
 */
export class SubmitCommunityCategoryRequest extends Message<SubmitCommunityCategoryRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 entity_id = 2;
   */
  entityId = 0;

  constructor(data?: PartialMessage<SubmitCommunityCategoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.SubmitCommunityCategoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubmitCommunityCategoryRequest {
    return new SubmitCommunityCategoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubmitCommunityCategoryRequest {
    return new SubmitCommunityCategoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubmitCommunityCategoryRequest {
    return new SubmitCommunityCategoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SubmitCommunityCategoryRequest | PlainMessage<SubmitCommunityCategoryRequest> | undefined, b: SubmitCommunityCategoryRequest | PlainMessage<SubmitCommunityCategoryRequest> | undefined): boolean {
    return proto3.util.equals(SubmitCommunityCategoryRequest, a, b);
  }
}

