// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/three_sixty_cities.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { SearchImagesRequest, SearchImagesResponse } from "./three_sixty_cities_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.ThreeSixtyCitiesService
 */
export const ThreeSixtyCitiesService = {
  typeName: "avn.connect.v1.ThreeSixtyCitiesService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.ThreeSixtyCitiesService.SearchImages
     */
    searchImages: {
      name: "SearchImages",
      I: SearchImagesRequest,
      O: SearchImagesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

