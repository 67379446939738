// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/lesson_context.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Quaternion, Vector3 } from "./geometry_pb.js";

/**
 * @generated from message avn.connect.v1.LessonContext
 */
export class LessonContext extends Message<LessonContext> {
  /**
   * TODO: Lesson plan, learning module modules, POIs - i.e. soft context for the guide rather than forced "focus" context
   *
   * @generated from field: optional avn.connect.v1.LessonFocus focus = 1;
   */
  focus?: LessonFocus;

  constructor(data?: PartialMessage<LessonContext>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.LessonContext";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "focus", kind: "message", T: LessonFocus, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonContext {
    return new LessonContext().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonContext {
    return new LessonContext().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonContext {
    return new LessonContext().fromJsonString(jsonString, options);
  }

  static equals(a: LessonContext | PlainMessage<LessonContext> | undefined, b: LessonContext | PlainMessage<LessonContext> | undefined): boolean {
    return proto3.util.equals(LessonContext, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.LessonFocus
 */
export class LessonFocus extends Message<LessonFocus> {
  /**
   * @generated from field: string room_id = 1;
   */
  roomId = "";

  /**
   * @generated from field: string asset_id = 2;
   */
  assetId = "";

  /**
   * @generated from field: optional avn.connect.v1.Vector3 position = 3;
   */
  position?: Vector3;

  /**
   * @generated from field: optional avn.connect.v1.Quaternion orientation = 4;
   */
  orientation?: Quaternion;

  /**
   * Should the user be allowed to go back?
   *
   * @generated from field: bool back_lock = 5;
   */
  backLock = false;

  /**
   * Should the user be allowed to use the general scene selector?
   *
   * @generated from field: bool explore_lock = 6;
   */
  exploreLock = false;

  /**
   * Should the user be focused exclusively on this scene or be allowed to navigate to linked scenes?
   *
   * @generated from field: bool navigation_lock = 7;
   */
  navigationLock = false;

  constructor(data?: PartialMessage<LessonFocus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.LessonFocus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "asset_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "position", kind: "message", T: Vector3, opt: true },
    { no: 4, name: "orientation", kind: "message", T: Quaternion, opt: true },
    { no: 5, name: "back_lock", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "explore_lock", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "navigation_lock", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LessonFocus {
    return new LessonFocus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LessonFocus {
    return new LessonFocus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LessonFocus {
    return new LessonFocus().fromJsonString(jsonString, options);
  }

  static equals(a: LessonFocus | PlainMessage<LessonFocus> | undefined, b: LessonFocus | PlainMessage<LessonFocus> | undefined): boolean {
    return proto3.util.equals(LessonFocus, a, b);
  }
}

