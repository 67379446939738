// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/organizations.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetEntityRequest } from "./entities_pb.js";
import { JoinOrganizationRequest, JoinOrganizationResponse, Organization } from "./organizations_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.OrganizationService
 */
export const OrganizationService = {
  typeName: "avn.connect.v1.OrganizationService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.OrganizationService.GetOrganization
     */
    getOrganization: {
      name: "GetOrganization",
      I: GetEntityRequest,
      O: Organization,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.OrganizationService.JoinOrganization
     */
    joinOrganization: {
      name: "JoinOrganization",
      I: JoinOrganizationRequest,
      O: JoinOrganizationResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

