// prototype class for hash functions
export abstract class HashBase {
  constructor(private _blockSize: number, private _finalSize: number) {
    this._block = new Uint8Array(_blockSize)
  }

  private _block: Uint8Array
  private _len = 0

  update(data: Uint8Array) {
    const block = this._block
    const blockSize = this._blockSize
    const length = data.length
    let accum = this._len

    for (let offset = 0; offset < length;) {
      let assigned = accum % blockSize
      let remainder = Math.min(length - offset, blockSize - assigned)

      for (let i = 0; i < remainder; i++) {
        block[assigned + i] = data[offset + i]!
      }

      accum += remainder
      offset += remainder

      if ((accum % blockSize) === 0) {
        this._update(block)
      }
    }

    this._len += length
    return this
  }

  digest(): Uint8Array {
    let rem = this._len % this._blockSize

    this._block[rem] = 0x80

    // zero (rem + 1) trailing bits, where (rem + 1) is the smallest
    // non-negative solution to the equation (length + 1 + (rem + 1)) === finalSize mod blockSize
    this._block.fill(0, rem + 1)

    if (rem >= this._finalSize) {
      this._update(this._block)
      this._block.fill(0)
    }

    const view = new DataView(this._block.buffer)

    const bits = this._len * 8

    // uint32
    if (bits <= 0xffffffff) {
      view.setUint32(this._blockSize - 4, bits, false)
    // uint64
    } else {
      let lowBits = (bits & 0xffffffff) >>> 0
      let highBits = (bits - lowBits) / 0x100000000

      view.setUint32(this._blockSize - 8, highBits, false)
      view.setUint32(this._blockSize - 4, lowBits, false)
    }

    this._update(this._block)
    return this._hash()
  }

  protected abstract _update(buffer: Uint8Array): void
  protected abstract _hash(): Uint8Array

}