// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/geometry.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message avn.connect.v1.Vector3
 */
export class Vector3 extends Message<Vector3> {
  /**
   * @generated from field: double x = 1;
   */
  x = 0;

  /**
   * @generated from field: double y = 2;
   */
  y = 0;

  /**
   * @generated from field: double z = 3;
   */
  z = 0;

  constructor(data?: PartialMessage<Vector3>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Vector3";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "x", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "y", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "z", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Vector3 {
    return new Vector3().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Vector3 {
    return new Vector3().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Vector3 {
    return new Vector3().fromJsonString(jsonString, options);
  }

  static equals(a: Vector3 | PlainMessage<Vector3> | undefined, b: Vector3 | PlainMessage<Vector3> | undefined): boolean {
    return proto3.util.equals(Vector3, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.Quaternion
 */
export class Quaternion extends Message<Quaternion> {
  /**
   * @generated from field: double x = 1;
   */
  x = 0;

  /**
   * @generated from field: double y = 2;
   */
  y = 0;

  /**
   * @generated from field: double z = 3;
   */
  z = 0;

  /**
   * @generated from field: double w = 4;
   */
  w = 0;

  constructor(data?: PartialMessage<Quaternion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Quaternion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "x", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "y", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "z", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "w", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Quaternion {
    return new Quaternion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Quaternion {
    return new Quaternion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Quaternion {
    return new Quaternion().fromJsonString(jsonString, options);
  }

  static equals(a: Quaternion | PlainMessage<Quaternion> | undefined, b: Quaternion | PlainMessage<Quaternion> | undefined): boolean {
    return proto3.util.equals(Quaternion, a, b);
  }
}

