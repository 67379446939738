// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/media.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Authorization } from "./authorization_pb.js";
import { OperationState } from "./operations_pb.js";

/**
 * Video stream types
 *
 * @generated from enum avn.connect.v1.MediaStreamType
 */
export enum MediaStreamType {
  /**
   * @generated from enum value: MEDIA_STREAM_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: MEDIA_STREAM_TYPE_UNKNOWN = 1;
   */
  UNKNOWN = 1,

  /**
   * @generated from enum value: MEDIA_STREAM_TYPE_VIDEO = 2;
   */
  VIDEO = 2,

  /**
   * @generated from enum value: MEDIA_STREAM_TYPE_AUDIO = 3;
   */
  AUDIO = 3,

  /**
   * @generated from enum value: MEDIA_STREAM_TYPE_DATA = 4;
   */
  DATA = 4,

  /**
   * @generated from enum value: MEDIA_STREAM_TYPE_SUBTITLE = 5;
   */
  SUBTITLE = 5,

  /**
   * @generated from enum value: MEDIA_STREAM_TYPE_ATTACHMENT = 6;
   */
  ATTACHMENT = 6,

  /**
   * @generated from enum value: MEDIA_STREAM_TYPE_NB = 7;
   */
  NB = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(MediaStreamType)
proto3.util.setEnumType(MediaStreamType, "avn.connect.v1.MediaStreamType", [
  { no: 0, name: "MEDIA_STREAM_TYPE_UNSPECIFIED" },
  { no: 1, name: "MEDIA_STREAM_TYPE_UNKNOWN" },
  { no: 2, name: "MEDIA_STREAM_TYPE_VIDEO" },
  { no: 3, name: "MEDIA_STREAM_TYPE_AUDIO" },
  { no: 4, name: "MEDIA_STREAM_TYPE_DATA" },
  { no: 5, name: "MEDIA_STREAM_TYPE_SUBTITLE" },
  { no: 6, name: "MEDIA_STREAM_TYPE_ATTACHMENT" },
  { no: 7, name: "MEDIA_STREAM_TYPE_NB" },
]);

/**
 * @generated from enum avn.connect.v1.MediaCompatibilityArea
 */
export enum MediaCompatibilityArea {
  /**
   * @generated from enum value: MEDIA_COMPATIBILITY_AREA_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: MEDIA_COMPATIBILITY_AREA_CONTAINER = 1;
   */
  CONTAINER = 1,

  /**
   * @generated from enum value: MEDIA_COMPATIBILITY_AREA_VIDEO_CODEC = 2;
   */
  VIDEO_CODEC = 2,

  /**
   * @generated from enum value: MEDIA_COMPATIBILITY_AREA_AUDIO_CODEC = 3;
   */
  AUDIO_CODEC = 3,

  /**
   * @generated from enum value: MEDIA_COMPATIBILITY_AREA_OTHER_CODEC = 4;
   */
  OTHER_CODEC = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(MediaCompatibilityArea)
proto3.util.setEnumType(MediaCompatibilityArea, "avn.connect.v1.MediaCompatibilityArea", [
  { no: 0, name: "MEDIA_COMPATIBILITY_AREA_UNSPECIFIED" },
  { no: 1, name: "MEDIA_COMPATIBILITY_AREA_CONTAINER" },
  { no: 2, name: "MEDIA_COMPATIBILITY_AREA_VIDEO_CODEC" },
  { no: 3, name: "MEDIA_COMPATIBILITY_AREA_AUDIO_CODEC" },
  { no: 4, name: "MEDIA_COMPATIBILITY_AREA_OTHER_CODEC" },
]);

/**
 * @generated from enum avn.connect.v1.MediaCompatibilityReason
 */
export enum MediaCompatibilityReason {
  /**
   * @generated from enum value: MEDIA_COMPATIBILITY_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * Errors (incompatible)
   *
   * @generated from enum value: MEDIA_COMPATIBILITY_REASON_NOT_SUPPORTED = 1;
   */
  NOT_SUPPORTED = 1,

  /**
   * @generated from enum value: MEDIA_COMPATIBILITY_REASON_MAX_PIXEL_WIDTH = 2;
   */
  MAX_PIXEL_WIDTH = 2,

  /**
   * @generated from enum value: MEDIA_COMPATIBILITY_REASON_MAX_PIXEL_HEIGHT = 3;
   */
  MAX_PIXEL_HEIGHT = 3,

  /**
   * @generated from enum value: MEDIA_COMPATIBILITY_REASON_PIXEL_FORMAT = 4;
   */
  PIXEL_FORMAT = 4,

  /**
   * @generated from enum value: MEDIA_COMPATIBILITY_REASON_MAX_FRAMERATE = 5;
   */
  MAX_FRAMERATE = 5,

  /**
   * @generated from enum value: MEDIA_COMPATIBILITY_REASON_MAX_BITRATE = 6;
   */
  MAX_BITRATE = 6,

  /**
   * @generated from enum value: MEDIA_COMPATIBILITY_REASON_CHANNEL_COUNT = 7;
   */
  CHANNEL_COUNT = 7,

  /**
   * Warnings (compatible, but exceeding target)
   *
   * @generated from enum value: MEDIA_COMPATIBILITY_REASON_TARGET_BITRATE = 8;
   */
  TARGET_BITRATE = 8,
}
// Retrieve enum metadata with: proto3.getEnumType(MediaCompatibilityReason)
proto3.util.setEnumType(MediaCompatibilityReason, "avn.connect.v1.MediaCompatibilityReason", [
  { no: 0, name: "MEDIA_COMPATIBILITY_REASON_UNSPECIFIED" },
  { no: 1, name: "MEDIA_COMPATIBILITY_REASON_NOT_SUPPORTED" },
  { no: 2, name: "MEDIA_COMPATIBILITY_REASON_MAX_PIXEL_WIDTH" },
  { no: 3, name: "MEDIA_COMPATIBILITY_REASON_MAX_PIXEL_HEIGHT" },
  { no: 4, name: "MEDIA_COMPATIBILITY_REASON_PIXEL_FORMAT" },
  { no: 5, name: "MEDIA_COMPATIBILITY_REASON_MAX_FRAMERATE" },
  { no: 6, name: "MEDIA_COMPATIBILITY_REASON_MAX_BITRATE" },
  { no: 7, name: "MEDIA_COMPATIBILITY_REASON_CHANNEL_COUNT" },
  { no: 8, name: "MEDIA_COMPATIBILITY_REASON_TARGET_BITRATE" },
]);

/**
 * @generated from message avn.connect.v1.GetPreviewImageRequest
 */
export class GetPreviewImageRequest extends Message<GetPreviewImageRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: string media_url = 2;
   */
  mediaUrl = "";

  constructor(data?: PartialMessage<GetPreviewImageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetPreviewImageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "media_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPreviewImageRequest {
    return new GetPreviewImageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPreviewImageRequest {
    return new GetPreviewImageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPreviewImageRequest {
    return new GetPreviewImageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPreviewImageRequest | PlainMessage<GetPreviewImageRequest> | undefined, b: GetPreviewImageRequest | PlainMessage<GetPreviewImageRequest> | undefined): boolean {
    return proto3.util.equals(GetPreviewImageRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetPreviewImageResponse
 */
export class GetPreviewImageResponse extends Message<GetPreviewImageResponse> {
  /**
   * Unset if no preview could be created for the given file
   *
   * @generated from field: optional string media_url = 1;
   */
  mediaUrl?: string;

  constructor(data?: PartialMessage<GetPreviewImageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetPreviewImageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPreviewImageResponse {
    return new GetPreviewImageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPreviewImageResponse {
    return new GetPreviewImageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPreviewImageResponse {
    return new GetPreviewImageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPreviewImageResponse | PlainMessage<GetPreviewImageResponse> | undefined, b: GetPreviewImageResponse | PlainMessage<GetPreviewImageResponse> | undefined): boolean {
    return proto3.util.equals(GetPreviewImageResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetMetadataRequest
 */
export class GetMetadataRequest extends Message<GetMetadataRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: string media_url = 2;
   */
  mediaUrl = "";

  constructor(data?: PartialMessage<GetMetadataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetMetadataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "media_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMetadataRequest {
    return new GetMetadataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMetadataRequest {
    return new GetMetadataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMetadataRequest {
    return new GetMetadataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMetadataRequest | PlainMessage<GetMetadataRequest> | undefined, b: GetMetadataRequest | PlainMessage<GetMetadataRequest> | undefined): boolean {
    return proto3.util.equals(GetMetadataRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetImageMetadataResponse
 */
export class GetImageMetadataResponse extends Message<GetImageMetadataResponse> {
  /**
   * Width 
   *
   * @generated from field: float width = 1;
   */
  width = 0;

  /**
   * Height
   *
   * @generated from field: float height = 2;
   */
  height = 0;

  /**
   * Media type
   *
   * @generated from field: string media_type = 3;
   */
  mediaType = "";

  /**
   * One of in,mm,cm,pt,pc,px,em,ex
   *
   * @generated from field: string width_units = 4;
   */
  widthUnits = "";

  /**
   * @generated from field: string height_units = 5;
   */
  heightUnits = "";

  /**
   * Orientation
   *
   * @generated from field: optional int32 orientation = 6;
   */
  orientation?: number;

  constructor(data?: PartialMessage<GetImageMetadataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetImageMetadataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "width", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "height", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "media_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "width_units", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "height_units", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "orientation", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetImageMetadataResponse {
    return new GetImageMetadataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetImageMetadataResponse {
    return new GetImageMetadataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetImageMetadataResponse {
    return new GetImageMetadataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetImageMetadataResponse | PlainMessage<GetImageMetadataResponse> | undefined, b: GetImageMetadataResponse | PlainMessage<GetImageMetadataResponse> | undefined): boolean {
    return proto3.util.equals(GetImageMetadataResponse, a, b);
  }
}

/**
 * Rational ratio type used for media frame timing
 *
 * @generated from message avn.connect.v1.MediaRational
 */
export class MediaRational extends Message<MediaRational> {
  /**
   * @generated from field: int32 num = 1;
   */
  num = 0;

  /**
   * @generated from field: int32 den = 2;
   */
  den = 0;

  constructor(data?: PartialMessage<MediaRational>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.MediaRational";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "num", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "den", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaRational {
    return new MediaRational().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaRational {
    return new MediaRational().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaRational {
    return new MediaRational().fromJsonString(jsonString, options);
  }

  static equals(a: MediaRational | PlainMessage<MediaRational> | undefined, b: MediaRational | PlainMessage<MediaRational> | undefined): boolean {
    return proto3.util.equals(MediaRational, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.MediaStreamMetadata
 */
export class MediaStreamMetadata extends Message<MediaStreamMetadata> {
  /**
   * Format-specific stream ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * General type of the encoded data
   *
   * @generated from field: avn.connect.v1.MediaStreamType stream_type = 2;
   */
  streamType = MediaStreamType.UNSPECIFIED;

  /**
   * Is this the default stream of this type? (typically parsed from disposition)
   *
   * @generated from field: bool is_default = 3;
   */
  isDefault = false;

  /**
   * One of: hvc1, hev1, avc1, mp4a
   *
   * @generated from field: string codec_tag = 4;
   */
  codecTag = "";

  /**
   * One of: h264, hevc, mp3, aac
   *
   * @generated from field: string codec_name = 5;
   */
  codecName = "";

  /**
   * @generated from field: string codec_display_name = 6;
   */
  codecDisplayName = "";

  /**
   * Stream duration
   *
   * @generated from field: float duration_seconds = 7;
   */
  durationSeconds = 0;

  /**
   * The fundamental unit of time (in seconds) in terms of which frame timestamps are represented
   *
   * @generated from field: avn.connect.v1.MediaRational time_base = 8;
   */
  timeBase?: MediaRational;

  /**
   * Real base frame rate of the stream (in variable frame rate streams, this is the fastest rate to expect)
   *
   * @generated from field: avn.connect.v1.MediaRational real_base_framerate = 9;
   */
  realBaseFramerate?: MediaRational;

  /**
   * Average frame rate of the stream
   *
   * @generated from field: avn.connect.v1.MediaRational average_framerate = 10;
   */
  averageFramerate?: MediaRational;

  /**
   * The average bitrate of the encoded data (in bits per second)
   *
   * @generated from field: int64 bitrate_bits_per_second = 11;
   */
  bitrateBitsPerSecond = protoInt64.zero;

  /**
   * Number of frames in this stream (if known)
   *
   * @generated from field: optional int64 frame_count = 12;
   */
  frameCount?: bigint;

  /**
   * Video width
   *
   * @generated from field: optional int32 width_pixels = 13;
   */
  widthPixels?: number;

  /**
   * Video height
   *
   * @generated from field: optional int32 height_pixels = 14;
   */
  heightPixels?: number;

  /**
   * Video sample aspect ratio
   *
   * @generated from field: optional avn.connect.v1.MediaRational sample_aspect_ratio = 15;
   */
  sampleAspectRatio?: MediaRational;

  /**
   * Video display aspect ratio
   *
   * @generated from field: optional avn.connect.v1.MediaRational display_aspect_ratio = 16;
   */
  displayAspectRatio?: MediaRational;

  /**
   * Video pixel format
   *
   * @generated from field: optional string pixel_format = 17;
   */
  pixelFormat?: string;

  /**
   * Audio sample rate
   *
   * @generated from field: optional int32 sample_rate_hertz = 18;
   */
  sampleRateHertz?: number;

  /**
   * Audio channel count
   *
   * @generated from field: optional int32 channel_count = 19;
   */
  channelCount?: number;

  /**
   * Audio channel layout
   *
   * @generated from field: optional string channel_layout = 20;
   */
  channelLayout?: string;

  /**
   * Display directives such as "default", "dub", "captions", etc...
   *
   * @generated from field: repeated string dispositions = 21;
   */
  dispositions: string[] = [];

  /**
   * Additional metadata
   *
   * @generated from field: map<string, string> tags = 22;
   */
  tags: { [key: string]: string } = {};

  constructor(data?: PartialMessage<MediaStreamMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.MediaStreamMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "stream_type", kind: "enum", T: proto3.getEnumType(MediaStreamType) },
    { no: 3, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "codec_tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "codec_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "codec_display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "duration_seconds", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "time_base", kind: "message", T: MediaRational },
    { no: 9, name: "real_base_framerate", kind: "message", T: MediaRational },
    { no: 10, name: "average_framerate", kind: "message", T: MediaRational },
    { no: 11, name: "bitrate_bits_per_second", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "frame_count", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 13, name: "width_pixels", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "height_pixels", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 15, name: "sample_aspect_ratio", kind: "message", T: MediaRational, opt: true },
    { no: 16, name: "display_aspect_ratio", kind: "message", T: MediaRational, opt: true },
    { no: 17, name: "pixel_format", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "sample_rate_hertz", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 19, name: "channel_count", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 20, name: "channel_layout", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 21, name: "dispositions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 22, name: "tags", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaStreamMetadata {
    return new MediaStreamMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaStreamMetadata {
    return new MediaStreamMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaStreamMetadata {
    return new MediaStreamMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: MediaStreamMetadata | PlainMessage<MediaStreamMetadata> | undefined, b: MediaStreamMetadata | PlainMessage<MediaStreamMetadata> | undefined): boolean {
    return proto3.util.equals(MediaStreamMetadata, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.MediaFormatMetadata
 */
export class MediaFormatMetadata extends Message<MediaFormatMetadata> {
  /**
   * Container information (may include synonyms such as mov, mp4, m4a, 3gp, 3g2, mj2)
   *
   * @generated from field: repeated string container_formats = 1;
   */
  containerFormats: string[] = [];

  /**
   * @generated from field: string container_display_name = 2;
   */
  containerDisplayName = "";

  /**
   * Overall duration
   *
   * @generated from field: float duration_seconds = 3;
   */
  durationSeconds = 0;

  /**
   * Total size
   *
   * @generated from field: int64 size_bytes = 4;
   */
  sizeBytes = protoInt64.zero;

  /**
   * Estimated average bitrate
   *
   * @generated from field: int64 bitrate_bits_per_second = 5;
   */
  bitrateBitsPerSecond = protoInt64.zero;

  /**
   * Additional metadata
   *
   * @generated from field: map<string, string> tags = 6;
   */
  tags: { [key: string]: string } = {};

  constructor(data?: PartialMessage<MediaFormatMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.MediaFormatMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "container_formats", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "container_display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "duration_seconds", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "size_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "bitrate_bits_per_second", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "tags", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaFormatMetadata {
    return new MediaFormatMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaFormatMetadata {
    return new MediaFormatMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaFormatMetadata {
    return new MediaFormatMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: MediaFormatMetadata | PlainMessage<MediaFormatMetadata> | undefined, b: MediaFormatMetadata | PlainMessage<MediaFormatMetadata> | undefined): boolean {
    return proto3.util.equals(MediaFormatMetadata, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetVideoMetadataResponse
 */
export class GetVideoMetadataResponse extends Message<GetVideoMetadataResponse> {
  /**
   * @generated from field: avn.connect.v1.MediaFormatMetadata format = 1;
   */
  format?: MediaFormatMetadata;

  /**
   * @generated from field: repeated avn.connect.v1.MediaStreamMetadata streams = 2;
   */
  streams: MediaStreamMetadata[] = [];

  constructor(data?: PartialMessage<GetVideoMetadataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetVideoMetadataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "format", kind: "message", T: MediaFormatMetadata },
    { no: 2, name: "streams", kind: "message", T: MediaStreamMetadata, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVideoMetadataResponse {
    return new GetVideoMetadataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVideoMetadataResponse {
    return new GetVideoMetadataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVideoMetadataResponse {
    return new GetVideoMetadataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetVideoMetadataResponse | PlainMessage<GetVideoMetadataResponse> | undefined, b: GetVideoMetadataResponse | PlainMessage<GetVideoMetadataResponse> | undefined): boolean {
    return proto3.util.equals(GetVideoMetadataResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.TranscodeImageSpec
 */
export class TranscodeImageSpec extends Message<TranscodeImageSpec> {
  /**
   * Maximum height or width in pixels
   * Must be one of 8, 16, 32, 64, 128, 256, 512, 1024, or 2048
   * -1 indicates that the original image should be returned
   *
   * @generated from field: int32 max_size_pixels = 1;
   */
  maxSizePixels = 0;

  constructor(data?: PartialMessage<TranscodeImageSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.TranscodeImageSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "max_size_pixels", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TranscodeImageSpec {
    return new TranscodeImageSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TranscodeImageSpec {
    return new TranscodeImageSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TranscodeImageSpec {
    return new TranscodeImageSpec().fromJsonString(jsonString, options);
  }

  static equals(a: TranscodeImageSpec | PlainMessage<TranscodeImageSpec> | undefined, b: TranscodeImageSpec | PlainMessage<TranscodeImageSpec> | undefined): boolean {
    return proto3.util.equals(TranscodeImageSpec, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.TranscodeImageRequest
 */
export class TranscodeImageRequest extends Message<TranscodeImageRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: string media_url = 2;
   */
  mediaUrl = "";

  /**
   * @generated from field: avn.connect.v1.TranscodeImageSpec transcode_spec = 3;
   */
  transcodeSpec?: TranscodeImageSpec;

  constructor(data?: PartialMessage<TranscodeImageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.TranscodeImageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "media_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "transcode_spec", kind: "message", T: TranscodeImageSpec },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TranscodeImageRequest {
    return new TranscodeImageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TranscodeImageRequest {
    return new TranscodeImageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TranscodeImageRequest {
    return new TranscodeImageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TranscodeImageRequest | PlainMessage<TranscodeImageRequest> | undefined, b: TranscodeImageRequest | PlainMessage<TranscodeImageRequest> | undefined): boolean {
    return proto3.util.equals(TranscodeImageRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.TranscodeImageResponse
 */
export class TranscodeImageResponse extends Message<TranscodeImageResponse> {
  /**
   * @generated from field: string media_url = 1;
   */
  mediaUrl = "";

  constructor(data?: PartialMessage<TranscodeImageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.TranscodeImageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TranscodeImageResponse {
    return new TranscodeImageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TranscodeImageResponse {
    return new TranscodeImageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TranscodeImageResponse {
    return new TranscodeImageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TranscodeImageResponse | PlainMessage<TranscodeImageResponse> | undefined, b: TranscodeImageResponse | PlainMessage<TranscodeImageResponse> | undefined): boolean {
    return proto3.util.equals(TranscodeImageResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.MediaFormatSpec
 */
export class MediaFormatSpec extends Message<MediaFormatSpec> {
  /**
   * @generated from field: string codec_name = 1;
   */
  codecName = "";

  /**
   * Supported tags such as hvc1 or hev1 (empty implies that all stream types supported)
   *
   * @generated from field: repeated string supported_codec_tags = 2;
   */
  supportedCodecTags: string[] = [];

  /**
   * Stream type to use this codec with
   *
   * @generated from field: avn.connect.v1.MediaStreamType stream_type = 3;
   */
  streamType = MediaStreamType.UNSPECIFIED;

  /**
   * Priority of this codec as a transcode target
   *
   * @generated from field: int32 priority = 4;
   */
  priority = 0;

  /**
   * Max width in pixels
   *
   * @generated from field: optional int32 max_width_pixels = 5;
   */
  maxWidthPixels?: number;

  /**
   * Target width when transcoding
   *
   * @generated from field: optional int32 target_width_pixels = 6;
   */
  targetWidthPixels?: number;

  /**
   * Max Height in pixels
   *
   * @generated from field: optional int32 max_height_pixels = 7;
   */
  maxHeightPixels?: number;

  /**
   * Target height when transcoding
   *
   * @generated from field: optional int32 target_height_pixels = 8;
   */
  targetHeightPixels?: number;

  /**
   * Supported pixel formats for video
   *
   * @generated from field: repeated string supported_pixel_formats = 9;
   */
  supportedPixelFormats: string[] = [];

  /**
   * Max frame rate
   *
   * @generated from field: optional int32 max_framerate = 10;
   */
  maxFramerate?: number;

  /**
   * Max bitrate
   *
   * @generated from field: optional int64 max_bitrate_bits_per_second = 11;
   */
  maxBitrateBitsPerSecond?: bigint;

  /**
   * Target bitrate when transcoding
   *
   * @generated from field: optional int64 target_bitrate_bits_per_second = 12;
   */
  targetBitrateBitsPerSecond?: bigint;

  /**
   * Target quality when transcoding
   *
   * @generated from field: optional int32 target_quality_percent = 15;
   */
  targetQualityPercent?: number;

  /**
   * Max number of audio channels
   *
   * @generated from field: optional int32 max_audio_channels = 13;
   */
  maxAudioChannels?: number;

  /**
   * Max audio sample rate
   *
   * @generated from field: optional int32 max_sample_rate_hertz = 14;
   */
  maxSampleRateHertz?: number;

  constructor(data?: PartialMessage<MediaFormatSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.MediaFormatSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "codec_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "supported_codec_tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "stream_type", kind: "enum", T: proto3.getEnumType(MediaStreamType) },
    { no: 4, name: "priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "max_width_pixels", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 6, name: "target_width_pixels", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "max_height_pixels", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 8, name: "target_height_pixels", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "supported_pixel_formats", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "max_framerate", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "max_bitrate_bits_per_second", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 12, name: "target_bitrate_bits_per_second", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 15, name: "target_quality_percent", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 13, name: "max_audio_channels", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "max_sample_rate_hertz", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaFormatSpec {
    return new MediaFormatSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaFormatSpec {
    return new MediaFormatSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaFormatSpec {
    return new MediaFormatSpec().fromJsonString(jsonString, options);
  }

  static equals(a: MediaFormatSpec | PlainMessage<MediaFormatSpec> | undefined, b: MediaFormatSpec | PlainMessage<MediaFormatSpec> | undefined): boolean {
    return proto3.util.equals(MediaFormatSpec, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.MediaDeviceSpec
 */
export class MediaDeviceSpec extends Message<MediaDeviceSpec> {
  /**
   * @generated from field: string media_device_id = 1;
   */
  mediaDeviceId = "";

  /**
   * @generated from field: string media_device_name = 2;
   */
  mediaDeviceName = "";

  /**
   * First container is the preferred option when transcoding
   *
   * @generated from field: repeated string supported_container_formats = 3;
   */
  supportedContainerFormats: string[] = [];

  /**
   * Supported codecs by codec tag
   *
   * @generated from field: map<string, avn.connect.v1.MediaFormatSpec> supported_codecs = 4;
   */
  supportedCodecs: { [key: string]: MediaFormatSpec } = {};

  constructor(data?: PartialMessage<MediaDeviceSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.MediaDeviceSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media_device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "media_device_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "supported_container_formats", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "supported_codecs", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: MediaFormatSpec} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaDeviceSpec {
    return new MediaDeviceSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaDeviceSpec {
    return new MediaDeviceSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaDeviceSpec {
    return new MediaDeviceSpec().fromJsonString(jsonString, options);
  }

  static equals(a: MediaDeviceSpec | PlainMessage<MediaDeviceSpec> | undefined, b: MediaDeviceSpec | PlainMessage<MediaDeviceSpec> | undefined): boolean {
    return proto3.util.equals(MediaDeviceSpec, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.TranscodeVideoRequest
 */
export class TranscodeVideoRequest extends Message<TranscodeVideoRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * The media file to transcode
   *
   * @generated from field: string media_url = 2;
   */
  mediaUrl = "";

  /**
   * The target spec to transcode to
   *
   * @generated from field: avn.connect.v1.MediaDeviceSpec target_device_spec = 3;
   */
  targetDeviceSpec?: MediaDeviceSpec;

  /**
   * Force transcoding even if the media file is already on spec
   *
   * @generated from field: bool force_transcode = 4;
   */
  forceTranscode = false;

  constructor(data?: PartialMessage<TranscodeVideoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.TranscodeVideoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "media_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "target_device_spec", kind: "message", T: MediaDeviceSpec },
    { no: 4, name: "force_transcode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TranscodeVideoRequest {
    return new TranscodeVideoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TranscodeVideoRequest {
    return new TranscodeVideoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TranscodeVideoRequest {
    return new TranscodeVideoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TranscodeVideoRequest | PlainMessage<TranscodeVideoRequest> | undefined, b: TranscodeVideoRequest | PlainMessage<TranscodeVideoRequest> | undefined): boolean {
    return proto3.util.equals(TranscodeVideoRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.TranscodeVideoResponse
 */
export class TranscodeVideoResponse extends Message<TranscodeVideoResponse> {
  /**
   * State of the transcoding process
   *
   * @generated from field: avn.connect.v1.OperationState state = 1;
   */
  state = OperationState.UNSPECIFIED;

  /**
   * Set once the job has finished regardless of outcome
   *
   * @generated from field: optional avn.connect.v1.TranscodeVideoResult result = 2;
   */
  result?: TranscodeVideoResult;

  /**
   * May be set when processing to indicated progress
   *
   * @generated from field: optional int32 progress_percent = 3;
   */
  progressPercent?: number;

  constructor(data?: PartialMessage<TranscodeVideoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.TranscodeVideoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(OperationState) },
    { no: 2, name: "result", kind: "message", T: TranscodeVideoResult, opt: true },
    { no: 3, name: "progress_percent", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TranscodeVideoResponse {
    return new TranscodeVideoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TranscodeVideoResponse {
    return new TranscodeVideoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TranscodeVideoResponse {
    return new TranscodeVideoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TranscodeVideoResponse | PlainMessage<TranscodeVideoResponse> | undefined, b: TranscodeVideoResponse | PlainMessage<TranscodeVideoResponse> | undefined): boolean {
    return proto3.util.equals(TranscodeVideoResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.TranscodeVideoResult
 */
export class TranscodeVideoResult extends Message<TranscodeVideoResult> {
  /**
   * Set if the job succeeded
   *
   * @generated from field: optional string media_url = 1;
   */
  mediaUrl?: string;

  /**
   * Populated if there were transcode errors
   *
   * @generated from field: repeated string errors = 2;
   */
  errors: string[] = [];

  /**
   * Populated if there were transcode warnings
   *
   * @generated from field: repeated string warnings = 3;
   */
  warnings: string[] = [];

  constructor(data?: PartialMessage<TranscodeVideoResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.TranscodeVideoResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "errors", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "warnings", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TranscodeVideoResult {
    return new TranscodeVideoResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TranscodeVideoResult {
    return new TranscodeVideoResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TranscodeVideoResult {
    return new TranscodeVideoResult().fromJsonString(jsonString, options);
  }

  static equals(a: TranscodeVideoResult | PlainMessage<TranscodeVideoResult> | undefined, b: TranscodeVideoResult | PlainMessage<TranscodeVideoResult> | undefined): boolean {
    return proto3.util.equals(TranscodeVideoResult, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.MediaTypeExtension
 */
export class MediaTypeExtension extends Message<MediaTypeExtension> {
  /**
   * @generated from field: string extension = 1;
   */
  extension = "";

  /**
   * @generated from field: string media_type = 2;
   */
  mediaType = "";

  constructor(data?: PartialMessage<MediaTypeExtension>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.MediaTypeExtension";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "extension", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "media_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaTypeExtension {
    return new MediaTypeExtension().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaTypeExtension {
    return new MediaTypeExtension().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaTypeExtension {
    return new MediaTypeExtension().fromJsonString(jsonString, options);
  }

  static equals(a: MediaTypeExtension | PlainMessage<MediaTypeExtension> | undefined, b: MediaTypeExtension | PlainMessage<MediaTypeExtension> | undefined): boolean {
    return proto3.util.equals(MediaTypeExtension, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetMediaTypeExtensionMapRequest
 */
export class GetMediaTypeExtensionMapRequest extends Message<GetMediaTypeExtensionMapRequest> {
  constructor(data?: PartialMessage<GetMediaTypeExtensionMapRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetMediaTypeExtensionMapRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMediaTypeExtensionMapRequest {
    return new GetMediaTypeExtensionMapRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMediaTypeExtensionMapRequest {
    return new GetMediaTypeExtensionMapRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMediaTypeExtensionMapRequest {
    return new GetMediaTypeExtensionMapRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMediaTypeExtensionMapRequest | PlainMessage<GetMediaTypeExtensionMapRequest> | undefined, b: GetMediaTypeExtensionMapRequest | PlainMessage<GetMediaTypeExtensionMapRequest> | undefined): boolean {
    return proto3.util.equals(GetMediaTypeExtensionMapRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetMediaTypeExtensionMapResponse
 */
export class GetMediaTypeExtensionMapResponse extends Message<GetMediaTypeExtensionMapResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.MediaTypeExtension media_types = 1;
   */
  mediaTypes: MediaTypeExtension[] = [];

  constructor(data?: PartialMessage<GetMediaTypeExtensionMapResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetMediaTypeExtensionMapResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media_types", kind: "message", T: MediaTypeExtension, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMediaTypeExtensionMapResponse {
    return new GetMediaTypeExtensionMapResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMediaTypeExtensionMapResponse {
    return new GetMediaTypeExtensionMapResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMediaTypeExtensionMapResponse {
    return new GetMediaTypeExtensionMapResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetMediaTypeExtensionMapResponse | PlainMessage<GetMediaTypeExtensionMapResponse> | undefined, b: GetMediaTypeExtensionMapResponse | PlainMessage<GetMediaTypeExtensionMapResponse> | undefined): boolean {
    return proto3.util.equals(GetMediaTypeExtensionMapResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.MediaCompatibilityIssue
 */
export class MediaCompatibilityIssue extends Message<MediaCompatibilityIssue> {
  /**
   * @generated from field: avn.connect.v1.MediaCompatibilityArea area = 1;
   */
  area = MediaCompatibilityArea.UNSPECIFIED;

  /**
   * @generated from field: avn.connect.v1.MediaCompatibilityReason reason = 2;
   */
  reason = MediaCompatibilityReason.UNSPECIFIED;

  /**
   * @generated from field: string detail = 3;
   */
  detail = "";

  constructor(data?: PartialMessage<MediaCompatibilityIssue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.MediaCompatibilityIssue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "area", kind: "enum", T: proto3.getEnumType(MediaCompatibilityArea) },
    { no: 2, name: "reason", kind: "enum", T: proto3.getEnumType(MediaCompatibilityReason) },
    { no: 3, name: "detail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaCompatibilityIssue {
    return new MediaCompatibilityIssue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaCompatibilityIssue {
    return new MediaCompatibilityIssue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaCompatibilityIssue {
    return new MediaCompatibilityIssue().fromJsonString(jsonString, options);
  }

  static equals(a: MediaCompatibilityIssue | PlainMessage<MediaCompatibilityIssue> | undefined, b: MediaCompatibilityIssue | PlainMessage<MediaCompatibilityIssue> | undefined): boolean {
    return proto3.util.equals(MediaCompatibilityIssue, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.CheckMediaCompatibilityRequest
 */
export class CheckMediaCompatibilityRequest extends Message<CheckMediaCompatibilityRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: string media_url = 2;
   */
  mediaUrl = "";

  /**
   * @generated from field: avn.connect.v1.MediaDeviceSpec target_device_spec = 3;
   */
  targetDeviceSpec?: MediaDeviceSpec;

  constructor(data?: PartialMessage<CheckMediaCompatibilityRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CheckMediaCompatibilityRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "media_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "target_device_spec", kind: "message", T: MediaDeviceSpec },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckMediaCompatibilityRequest {
    return new CheckMediaCompatibilityRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckMediaCompatibilityRequest {
    return new CheckMediaCompatibilityRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckMediaCompatibilityRequest {
    return new CheckMediaCompatibilityRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CheckMediaCompatibilityRequest | PlainMessage<CheckMediaCompatibilityRequest> | undefined, b: CheckMediaCompatibilityRequest | PlainMessage<CheckMediaCompatibilityRequest> | undefined): boolean {
    return proto3.util.equals(CheckMediaCompatibilityRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.CheckMediaCompatibilityResponse
 */
export class CheckMediaCompatibilityResponse extends Message<CheckMediaCompatibilityResponse> {
  /**
   * @generated from field: bool container_is_supported = 1;
   */
  containerIsSupported = false;

  /**
   * @generated from field: bool video_stream_is_available = 2;
   */
  videoStreamIsAvailable = false;

  /**
   * @generated from field: bool video_stream_is_supported = 3;
   */
  videoStreamIsSupported = false;

  /**
   * @generated from field: bool audio_stream_is_available = 4;
   */
  audioStreamIsAvailable = false;

  /**
   * @generated from field: bool audio_stream_is_supported = 5;
   */
  audioStreamIsSupported = false;

  /**
   * @generated from field: repeated avn.connect.v1.MediaCompatibilityIssue errors = 6;
   */
  errors: MediaCompatibilityIssue[] = [];

  /**
   * @generated from field: repeated avn.connect.v1.MediaCompatibilityIssue warnings = 7;
   */
  warnings: MediaCompatibilityIssue[] = [];

  constructor(data?: PartialMessage<CheckMediaCompatibilityResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CheckMediaCompatibilityResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "container_is_supported", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "video_stream_is_available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "video_stream_is_supported", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "audio_stream_is_available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "audio_stream_is_supported", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "errors", kind: "message", T: MediaCompatibilityIssue, repeated: true },
    { no: 7, name: "warnings", kind: "message", T: MediaCompatibilityIssue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckMediaCompatibilityResponse {
    return new CheckMediaCompatibilityResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckMediaCompatibilityResponse {
    return new CheckMediaCompatibilityResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckMediaCompatibilityResponse {
    return new CheckMediaCompatibilityResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CheckMediaCompatibilityResponse | PlainMessage<CheckMediaCompatibilityResponse> | undefined, b: CheckMediaCompatibilityResponse | PlainMessage<CheckMediaCompatibilityResponse> | undefined): boolean {
    return proto3.util.equals(CheckMediaCompatibilityResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetMediaDeviceSpecsRequest
 */
export class GetMediaDeviceSpecsRequest extends Message<GetMediaDeviceSpecsRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: repeated string media_device_ids = 2;
   */
  mediaDeviceIds: string[] = [];

  constructor(data?: PartialMessage<GetMediaDeviceSpecsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetMediaDeviceSpecsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "media_device_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMediaDeviceSpecsRequest {
    return new GetMediaDeviceSpecsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMediaDeviceSpecsRequest {
    return new GetMediaDeviceSpecsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMediaDeviceSpecsRequest {
    return new GetMediaDeviceSpecsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMediaDeviceSpecsRequest | PlainMessage<GetMediaDeviceSpecsRequest> | undefined, b: GetMediaDeviceSpecsRequest | PlainMessage<GetMediaDeviceSpecsRequest> | undefined): boolean {
    return proto3.util.equals(GetMediaDeviceSpecsRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetMediaDeviceSpecsResponse
 */
export class GetMediaDeviceSpecsResponse extends Message<GetMediaDeviceSpecsResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.MediaDeviceSpec media_device_specs = 1;
   */
  mediaDeviceSpecs: MediaDeviceSpec[] = [];

  constructor(data?: PartialMessage<GetMediaDeviceSpecsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetMediaDeviceSpecsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media_device_specs", kind: "message", T: MediaDeviceSpec, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMediaDeviceSpecsResponse {
    return new GetMediaDeviceSpecsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMediaDeviceSpecsResponse {
    return new GetMediaDeviceSpecsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMediaDeviceSpecsResponse {
    return new GetMediaDeviceSpecsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetMediaDeviceSpecsResponse | PlainMessage<GetMediaDeviceSpecsResponse> | undefined, b: GetMediaDeviceSpecsResponse | PlainMessage<GetMediaDeviceSpecsResponse> | undefined): boolean {
    return proto3.util.equals(GetMediaDeviceSpecsResponse, a, b);
  }
}

