// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/country.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message avn.connect.v1.Country
 */
export class Country extends Message<Country> {
  /**
   * @generated from field: string country_id = 1;
   */
  countryId = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string name_english = 4;
   */
  nameEnglish = "";

  /**
   * @generated from field: string default_language_id = 5;
   */
  defaultLanguageId = "";

  /**
   * @generated from field: string default_locale_id = 6;
   */
  defaultLocaleId = "";

  /**
   * @generated from field: int32 default_organization_id = 7;
   */
  defaultOrganizationId = 0;

  /**
   * @generated from field: optional int32 tag_id = 8;
   */
  tagId?: number;

  constructor(data?: PartialMessage<Country>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Country";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "country_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "name_english", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "default_language_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "default_locale_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "default_organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "tag_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Country {
    return new Country().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Country {
    return new Country().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Country {
    return new Country().fromJsonString(jsonString, options);
  }

  static equals(a: Country | PlainMessage<Country> | undefined, b: Country | PlainMessage<Country> | undefined): boolean {
    return proto3.util.equals(Country, a, b);
  }
}

