// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/users.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Authorization } from "./authorization_pb.js";
import { OrganizationMembership } from "./organization_membership_pb.js";

/**
 * @generated from message avn.connect.v1.GetOrganizationMembershipRequest
 */
export class GetOrganizationMembershipRequest extends Message<GetOrganizationMembershipRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 user_id = 2;
   */
  userId = 0;

  constructor(data?: PartialMessage<GetOrganizationMembershipRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetOrganizationMembershipRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrganizationMembershipRequest {
    return new GetOrganizationMembershipRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrganizationMembershipRequest {
    return new GetOrganizationMembershipRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrganizationMembershipRequest {
    return new GetOrganizationMembershipRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrganizationMembershipRequest | PlainMessage<GetOrganizationMembershipRequest> | undefined, b: GetOrganizationMembershipRequest | PlainMessage<GetOrganizationMembershipRequest> | undefined): boolean {
    return proto3.util.equals(GetOrganizationMembershipRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetOrganizationMembershipResponse
 */
export class GetOrganizationMembershipResponse extends Message<GetOrganizationMembershipResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.OrganizationMembership memberships = 1;
   */
  memberships: OrganizationMembership[] = [];

  constructor(data?: PartialMessage<GetOrganizationMembershipResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetOrganizationMembershipResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "memberships", kind: "message", T: OrganizationMembership, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetOrganizationMembershipResponse {
    return new GetOrganizationMembershipResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetOrganizationMembershipResponse {
    return new GetOrganizationMembershipResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetOrganizationMembershipResponse {
    return new GetOrganizationMembershipResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetOrganizationMembershipResponse | PlainMessage<GetOrganizationMembershipResponse> | undefined, b: GetOrganizationMembershipResponse | PlainMessage<GetOrganizationMembershipResponse> | undefined): boolean {
    return proto3.util.equals(GetOrganizationMembershipResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.MemberOrganization
 */
export class MemberOrganization extends Message<MemberOrganization> {
  /**
   * @generated from field: int32 organization_id = 1;
   */
  organizationId = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<MemberOrganization>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.MemberOrganization";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MemberOrganization {
    return new MemberOrganization().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MemberOrganization {
    return new MemberOrganization().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MemberOrganization {
    return new MemberOrganization().fromJsonString(jsonString, options);
  }

  static equals(a: MemberOrganization | PlainMessage<MemberOrganization> | undefined, b: MemberOrganization | PlainMessage<MemberOrganization> | undefined): boolean {
    return proto3.util.equals(MemberOrganization, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.SearchMemberOrganizationsRequest
 */
export class SearchMemberOrganizationsRequest extends Message<SearchMemberOrganizationsRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: optional string search_text = 2;
   */
  searchText?: string;

  /**
   * @generated from field: optional int32 page_size = 3;
   */
  pageSize?: number;

  /**
   * @generated from field: optional string page_token = 4;
   */
  pageToken?: string;

  constructor(data?: PartialMessage<SearchMemberOrganizationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.SearchMemberOrganizationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "search_text", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchMemberOrganizationsRequest {
    return new SearchMemberOrganizationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchMemberOrganizationsRequest {
    return new SearchMemberOrganizationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchMemberOrganizationsRequest {
    return new SearchMemberOrganizationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SearchMemberOrganizationsRequest | PlainMessage<SearchMemberOrganizationsRequest> | undefined, b: SearchMemberOrganizationsRequest | PlainMessage<SearchMemberOrganizationsRequest> | undefined): boolean {
    return proto3.util.equals(SearchMemberOrganizationsRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.SearchMemberOrganizationsResponse
 */
export class SearchMemberOrganizationsResponse extends Message<SearchMemberOrganizationsResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.MemberOrganization results = 1;
   */
  results: MemberOrganization[] = [];

  /**
   * @generated from field: optional string next_page_token = 2;
   */
  nextPageToken?: string;

  constructor(data?: PartialMessage<SearchMemberOrganizationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.SearchMemberOrganizationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "message", T: MemberOrganization, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchMemberOrganizationsResponse {
    return new SearchMemberOrganizationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchMemberOrganizationsResponse {
    return new SearchMemberOrganizationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchMemberOrganizationsResponse {
    return new SearchMemberOrganizationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SearchMemberOrganizationsResponse | PlainMessage<SearchMemberOrganizationsResponse> | undefined, b: SearchMemberOrganizationsResponse | PlainMessage<SearchMemberOrganizationsResponse> | undefined): boolean {
    return proto3.util.equals(SearchMemberOrganizationsResponse, a, b);
  }
}

