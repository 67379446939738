// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/thinglink.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { TransformMediaRequest, TransformMediaResponse } from "./thinglink_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.ThingLinkService
 */
export const ThingLinkService = {
  typeName: "avn.connect.v1.ThingLinkService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.ThingLinkService.TransformMedia
     */
    transformMedia: {
      name: "TransformMedia",
      I: TransformMediaRequest,
      O: TransformMediaResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

