// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/learning_modules.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

/**
 * TODO: SEARCHING
 *
 * @generated from service avn.connect.v1.LearningModuleService
 */
export const LearningModuleService = {
  typeName: "avn.connect.v1.LearningModuleService",
  methods: {
  }
} as const;

