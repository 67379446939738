// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/tags.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetTagGroupRequest, GetTagGroupResponse, GetTagsRequest, GetTagsResponse } from "./tags_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.TagService
 */
export const TagService = {
  typeName: "avn.connect.v1.TagService",
  methods: {
    /**
     * Resolve tag IDs
     *
     * @generated from rpc avn.connect.v1.TagService.GetTags
     */
    getTags: {
      name: "GetTags",
      I: GetTagsRequest,
      O: GetTagsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get all the details of a tag groups from an ID
     *
     * @generated from rpc avn.connect.v1.TagService.GetTagGroup
     */
    getTagGroup: {
      name: "GetTagGroup",
      I: GetTagGroupRequest,
      O: GetTagGroupResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

