// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/tags.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Translation } from "./translations_pb.js";
import { Authorization } from "./authorization_pb.js";

/**
 * @generated from enum avn.connect.v1.TagFilterCondition
 */
export enum TagFilterCondition {
  /**
   * @generated from enum value: TAG_FILTER_CONDITION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TAG_FILTER_CONDITION_HAS_ANY_OF = 1;
   */
  HAS_ANY_OF = 1,

  /**
   * @generated from enum value: TAG_FILTER_CONDITION_HAS_ALL_OF = 2;
   */
  HAS_ALL_OF = 2,

  /**
   * @generated from enum value: TAG_FILTER_CONDITION_HAS_NONE_OF = 3;
   */
  HAS_NONE_OF = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(TagFilterCondition)
proto3.util.setEnumType(TagFilterCondition, "avn.connect.v1.TagFilterCondition", [
  { no: 0, name: "TAG_FILTER_CONDITION_UNSPECIFIED" },
  { no: 1, name: "TAG_FILTER_CONDITION_HAS_ANY_OF" },
  { no: 2, name: "TAG_FILTER_CONDITION_HAS_ALL_OF" },
  { no: 3, name: "TAG_FILTER_CONDITION_HAS_NONE_OF" },
]);

/**
 * @generated from message avn.connect.v1.Tag
 */
export class Tag extends Message<Tag> {
  /**
   * @generated from field: int32 tag_id = 1;
   */
  tagId = 0;

  /**
   * @generated from field: avn.connect.v1.Translation name = 2;
   */
  name?: Translation;

  /**
   * @generated from field: int32 tag_group_id = 3;
   */
  tagGroupId = 0;

  /**
   * @generated from field: int32 sort_order = 4;
   */
  sortOrder = 0;

  /**
   * @generated from field: string hex_color = 5;
   */
  hexColor = "";

  constructor(data?: PartialMessage<Tag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Tag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "message", T: Translation },
    { no: 3, name: "tag_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "sort_order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "hex_color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Tag {
    return new Tag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Tag {
    return new Tag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Tag {
    return new Tag().fromJsonString(jsonString, options);
  }

  static equals(a: Tag | PlainMessage<Tag> | undefined, b: Tag | PlainMessage<Tag> | undefined): boolean {
    return proto3.util.equals(Tag, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.TagGroup
 */
export class TagGroup extends Message<TagGroup> {
  /**
   * @generated from field: int32 tag_group_id = 1;
   */
  tagGroupId = 0;

  /**
   * @generated from field: avn.connect.v1.Translation name = 2;
   */
  name?: Translation;

  /**
   * @generated from field: string language_id = 3;
   */
  languageId = "";

  /**
   * @generated from field: repeated avn.connect.v1.Tag tags = 4;
   */
  tags: Tag[] = [];

  constructor(data?: PartialMessage<TagGroup>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.TagGroup";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "message", T: Translation },
    { no: 3, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tags", kind: "message", T: Tag, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TagGroup {
    return new TagGroup().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TagGroup {
    return new TagGroup().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TagGroup {
    return new TagGroup().fromJsonString(jsonString, options);
  }

  static equals(a: TagGroup | PlainMessage<TagGroup> | undefined, b: TagGroup | PlainMessage<TagGroup> | undefined): boolean {
    return proto3.util.equals(TagGroup, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetTagsRequest
 */
export class GetTagsRequest extends Message<GetTagsRequest> {
  /**
   * @generated from field: repeated int32 tag_ids = 1;
   */
  tagIds: number[] = [];

  /**
   * @generated from field: optional string language_id = 2;
   */
  languageId?: string;

  constructor(data?: PartialMessage<GetTagsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetTagsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag_ids", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 2, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTagsRequest {
    return new GetTagsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTagsRequest {
    return new GetTagsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTagsRequest {
    return new GetTagsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTagsRequest | PlainMessage<GetTagsRequest> | undefined, b: GetTagsRequest | PlainMessage<GetTagsRequest> | undefined): boolean {
    return proto3.util.equals(GetTagsRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetTagsResponse
 */
export class GetTagsResponse extends Message<GetTagsResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.Tag tags = 1;
   */
  tags: Tag[] = [];

  constructor(data?: PartialMessage<GetTagsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetTagsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tags", kind: "message", T: Tag, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTagsResponse {
    return new GetTagsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTagsResponse {
    return new GetTagsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTagsResponse {
    return new GetTagsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTagsResponse | PlainMessage<GetTagsResponse> | undefined, b: GetTagsResponse | PlainMessage<GetTagsResponse> | undefined): boolean {
    return proto3.util.equals(GetTagsResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetTagGroupRequest
 */
export class GetTagGroupRequest extends Message<GetTagGroupRequest> {
  /**
   * @generated from field: int32 tag_group_id = 1;
   */
  tagGroupId = 0;

  /**
   * @generated from field: optional string language_id = 2;
   */
  languageId?: string;

  constructor(data?: PartialMessage<GetTagGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetTagGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag_group_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTagGroupRequest {
    return new GetTagGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTagGroupRequest {
    return new GetTagGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTagGroupRequest {
    return new GetTagGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTagGroupRequest | PlainMessage<GetTagGroupRequest> | undefined, b: GetTagGroupRequest | PlainMessage<GetTagGroupRequest> | undefined): boolean {
    return proto3.util.equals(GetTagGroupRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetTagGroupResponse
 */
export class GetTagGroupResponse extends Message<GetTagGroupResponse> {
  /**
   * @generated from field: avn.connect.v1.TagGroup tag_group = 1;
   */
  tagGroup?: TagGroup;

  constructor(data?: PartialMessage<GetTagGroupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetTagGroupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag_group", kind: "message", T: TagGroup },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTagGroupResponse {
    return new GetTagGroupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTagGroupResponse {
    return new GetTagGroupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTagGroupResponse {
    return new GetTagGroupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTagGroupResponse | PlainMessage<GetTagGroupResponse> | undefined, b: GetTagGroupResponse | PlainMessage<GetTagGroupResponse> | undefined): boolean {
    return proto3.util.equals(GetTagGroupResponse, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.AddTagsRequest
 */
export class AddTagsRequest extends Message<AddTagsRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 entity_id = 2;
   */
  entityId = 0;

  /**
   * @generated from field: repeated int32 tags = 3;
   */
  tags: number[] = [];

  /**
   * CLOUD_FILE_LEGACY_ID
   *
   * @generated from field: optional string legacy_id = 10;
   */
  legacyId?: string;

  constructor(data?: PartialMessage<AddTagsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.AddTagsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "tags", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 10, name: "legacy_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddTagsRequest {
    return new AddTagsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddTagsRequest {
    return new AddTagsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddTagsRequest {
    return new AddTagsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddTagsRequest | PlainMessage<AddTagsRequest> | undefined, b: AddTagsRequest | PlainMessage<AddTagsRequest> | undefined): boolean {
    return proto3.util.equals(AddTagsRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.RemoveTagsRequest
 */
export class RemoveTagsRequest extends Message<RemoveTagsRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: int32 entity_id = 2;
   */
  entityId = 0;

  /**
   * @generated from field: repeated int32 tags = 3;
   */
  tags: number[] = [];

  /**
   * CLOUD_FILE_LEGACY_ID
   *
   * @generated from field: optional string legacy_id = 10;
   */
  legacyId?: string;

  constructor(data?: PartialMessage<RemoveTagsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.RemoveTagsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "tags", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 10, name: "legacy_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveTagsRequest {
    return new RemoveTagsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveTagsRequest {
    return new RemoveTagsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveTagsRequest {
    return new RemoveTagsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveTagsRequest | PlainMessage<RemoveTagsRequest> | undefined, b: RemoveTagsRequest | PlainMessage<RemoveTagsRequest> | undefined): boolean {
    return proto3.util.equals(RemoveTagsRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.TagFilter
 */
export class TagFilter extends Message<TagFilter> {
  /**
   * @generated from field: avn.connect.v1.TagFilterCondition condition = 1;
   */
  condition = TagFilterCondition.UNSPECIFIED;

  /**
   * @generated from field: repeated int32 tags = 2;
   */
  tags: number[] = [];

  constructor(data?: PartialMessage<TagFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.TagFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "condition", kind: "enum", T: proto3.getEnumType(TagFilterCondition) },
    { no: 2, name: "tags", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TagFilter {
    return new TagFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TagFilter {
    return new TagFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TagFilter {
    return new TagFilter().fromJsonString(jsonString, options);
  }

  static equals(a: TagFilter | PlainMessage<TagFilter> | undefined, b: TagFilter | PlainMessage<TagFilter> | undefined): boolean {
    return proto3.util.equals(TagFilter, a, b);
  }
}

