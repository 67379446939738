// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/features.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message avn.connect.v1.UserInterfaceFeatures
 */
export class UserInterfaceFeatures extends Message<UserInterfaceFeatures> {
  /**
   * @generated from field: bool show_navbar = 1;
   */
  showNavbar = false;

  /**
   * @generated from field: bool show_sidebar = 2;
   */
  showSidebar = false;

  /**
   * @generated from field: bool show_room_entry_flow = 3;
   */
  showRoomEntryFlow = false;

  /**
   * @generated from field: bool show_teacher_notes = 10;
   */
  showTeacherNotes = false;

  /**
   * @generated from field: bool show_student_notes = 11;
   */
  showStudentNotes = false;

  /**
   * @generated from field: bool show_sign_in = 12;
   */
  showSignIn = false;

  /**
   * @generated from field: bool show_change_avatar = 13;
   */
  showChangeAvatar = false;

  /**
   * @generated from field: bool show_invite = 14;
   */
  showInvite = false;

  /**
   * @generated from field: bool show_pass = 15;
   */
  showPass = false;

  /**
   * @generated from field: bool show_object_list = 16;
   */
  showObjectList = false;

  /**
   * @generated from field: bool show_camera = 17;
   */
  showCamera = false;

  /**
   * @generated from field: bool show_photo = 18;
   */
  showPhoto = false;

  /**
   * @generated from field: bool show_voip = 19;
   */
  showVoip = false;

  /**
   * @generated from field: bool show_text = 20;
   */
  showText = false;

  /**
   * @generated from field: bool show_people_menu = 21;
   */
  showPeopleMenu = false;

  /**
   * @generated from field: bool show_account_info = 22;
   */
  showAccountInfo = false;

  /**
   * @generated from field: bool show_dimension_info = 23;
   */
  showDimensionInfo = false;

  /**
   * @generated from field: bool show_device_qr_invite = 24;
   */
  showDeviceQrInvite = false;

  /**
   * @generated from field: bool show_focus = 30;
   */
  showFocus = false;

  /**
   * @generated from field: bool show_mute = 31;
   */
  showMute = false;

  /**
   * @generated from field: bool show_analytics = 32;
   */
  showAnalytics = false;

  /**
   * @generated from field: bool show_start_tour = 33;
   */
  showStartTour = false;

  constructor(data?: PartialMessage<UserInterfaceFeatures>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.UserInterfaceFeatures";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "show_navbar", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "show_sidebar", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "show_room_entry_flow", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "show_teacher_notes", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "show_student_notes", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "show_sign_in", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "show_change_avatar", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "show_invite", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "show_pass", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "show_object_list", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "show_camera", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "show_photo", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "show_voip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "show_text", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "show_people_menu", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "show_account_info", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "show_dimension_info", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "show_device_qr_invite", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 30, name: "show_focus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 31, name: "show_mute", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 32, name: "show_analytics", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 33, name: "show_start_tour", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserInterfaceFeatures {
    return new UserInterfaceFeatures().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserInterfaceFeatures {
    return new UserInterfaceFeatures().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserInterfaceFeatures {
    return new UserInterfaceFeatures().fromJsonString(jsonString, options);
  }

  static equals(a: UserInterfaceFeatures | PlainMessage<UserInterfaceFeatures> | undefined, b: UserInterfaceFeatures | PlainMessage<UserInterfaceFeatures> | undefined): boolean {
    return proto3.util.equals(UserInterfaceFeatures, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.AvailableContent
 */
export class AvailableContent extends Message<AvailableContent> {
  /**
   * Hint of what channels should be shown for browsing (POSSIBLY DEPRECATED AS SHOULD BE FILTERED BY CLIENT)
   *
   * @generated from field: repeated int32 browsable_channels = 1;
   */
  browsableChannels: number[] = [];

  /**
   * Published content from these channels is available 
   *
   * @generated from field: repeated int32 licensed_channels = 2;
   */
  licensedChannels: number[] = [];

  /**
   * Published content in these categories is available
   *
   * @generated from field: repeated int32 licensed_categories = 4;
   */
  licensedCategories: number[] = [];

  /**
   * Published content owned exclusively by these users is available (i.e. from the users playlists)
   *
   * @generated from field: repeated int32 licensed_users = 5;
   */
  licensedUsers: number[] = [];

  constructor(data?: PartialMessage<AvailableContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.AvailableContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "browsable_channels", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 2, name: "licensed_channels", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 4, name: "licensed_categories", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 5, name: "licensed_users", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AvailableContent {
    return new AvailableContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AvailableContent {
    return new AvailableContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AvailableContent {
    return new AvailableContent().fromJsonString(jsonString, options);
  }

  static equals(a: AvailableContent | PlainMessage<AvailableContent> | undefined, b: AvailableContent | PlainMessage<AvailableContent> | undefined): boolean {
    return proto3.util.equals(AvailableContent, a, b);
  }
}

