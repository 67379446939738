// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/media.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CheckMediaCompatibilityRequest, CheckMediaCompatibilityResponse, GetImageMetadataResponse, GetMediaDeviceSpecsRequest, GetMediaDeviceSpecsResponse, GetMediaTypeExtensionMapRequest, GetMediaTypeExtensionMapResponse, GetMetadataRequest, GetPreviewImageRequest, GetPreviewImageResponse, GetVideoMetadataResponse, TranscodeImageRequest, TranscodeImageResponse, TranscodeVideoRequest, TranscodeVideoResponse } from "./media_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.MediaService
 */
export const MediaService = {
  typeName: "avn.connect.v1.MediaService",
  methods: {
    /**
     * Create a summary image for the given media file
     *
     * @generated from rpc avn.connect.v1.MediaService.GetPreviewImage
     */
    getPreviewImage: {
      name: "GetPreviewImage",
      I: GetPreviewImageRequest,
      O: GetPreviewImageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Metadata functions
     *
     * @generated from rpc avn.connect.v1.MediaService.GetImageMetadata
     */
    getImageMetadata: {
      name: "GetImageMetadata",
      I: GetMetadataRequest,
      O: GetImageMetadataResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.MediaService.GetVideoMetadata
     */
    getVideoMetadata: {
      name: "GetVideoMetadata",
      I: GetMetadataRequest,
      O: GetVideoMetadataResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.MediaService.CheckMediaCompatibility
     */
    checkMediaCompatibility: {
      name: "CheckMediaCompatibility",
      I: CheckMediaCompatibilityRequest,
      O: CheckMediaCompatibilityResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Transcoding functions
     *
     * @generated from rpc avn.connect.v1.MediaService.TranscodeImage
     */
    transcodeImage: {
      name: "TranscodeImage",
      I: TranscodeImageRequest,
      O: TranscodeImageResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.MediaService.TranscodeVideo
     */
    transcodeVideo: {
      name: "TranscodeVideo",
      I: TranscodeVideoRequest,
      O: TranscodeVideoResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * A mapping of well known file extensions to media types
     *
     * @generated from rpc avn.connect.v1.MediaService.GetMediaTypeExtensionMap
     */
    getMediaTypeExtensionMap: {
      name: "GetMediaTypeExtensionMap",
      I: GetMediaTypeExtensionMapRequest,
      O: GetMediaTypeExtensionMapResponse,
      kind: MethodKind.Unary,
    },
    /**
     * A mapping of recommended media specs for well known devices
     *
     * @generated from rpc avn.connect.v1.MediaService.GetMediaDeviceSpecs
     */
    getMediaDeviceSpecs: {
      name: "GetMediaDeviceSpecs",
      I: GetMediaDeviceSpecsRequest,
      O: GetMediaDeviceSpecsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

