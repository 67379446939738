// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/passes.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetPassRequest, GetPassResponse } from "./passes_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.PassService
 */
export const PassService = {
  typeName: "avn.connect.v1.PassService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.PassService.GetPass
     */
    getPass: {
      name: "GetPass",
      I: GetPassRequest,
      O: GetPassResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

