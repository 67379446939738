// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/three_sixty_cities.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Authorization } from "./authorization_pb.js";
import { TranscodeImageSpec } from "./media_pb.js";
import { EntityInfo } from "./entities_pb.js";

/**
 * @generated from message avn.connect.v1.SearchImagesRequest
 */
export class SearchImagesRequest extends Message<SearchImagesRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * @generated from field: string search_text = 2;
   */
  searchText = "";

  /**
   * @generated from field: optional int32 page_size = 3;
   */
  pageSize?: number;

  /**
   * @generated from field: optional string page_token = 4;
   */
  pageToken?: string;

  /**
   * Override to the user agent language
   *
   * @generated from field: optional string language_id = 5;
   */
  languageId?: string;

  /**
   * @generated from field: optional avn.connect.v1.TranscodeImageSpec icon_spec = 6;
   */
  iconSpec?: TranscodeImageSpec;

  /**
   * @generated from field: optional avn.connect.v1.TranscodeImageSpec preview_spec = 7;
   */
  previewSpec?: TranscodeImageSpec;

  constructor(data?: PartialMessage<SearchImagesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.SearchImagesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "search_text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "icon_spec", kind: "message", T: TranscodeImageSpec, opt: true },
    { no: 7, name: "preview_spec", kind: "message", T: TranscodeImageSpec, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchImagesRequest {
    return new SearchImagesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchImagesRequest {
    return new SearchImagesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchImagesRequest {
    return new SearchImagesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SearchImagesRequest | PlainMessage<SearchImagesRequest> | undefined, b: SearchImagesRequest | PlainMessage<SearchImagesRequest> | undefined): boolean {
    return proto3.util.equals(SearchImagesRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.SearchImagesResponse
 */
export class SearchImagesResponse extends Message<SearchImagesResponse> {
  /**
   * @generated from field: repeated avn.connect.v1.EntityInfo results = 1;
   */
  results: EntityInfo[] = [];

  /**
   * @generated from field: optional string next_page_token = 2;
   */
  nextPageToken?: string;

  constructor(data?: PartialMessage<SearchImagesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.SearchImagesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "message", T: EntityInfo, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchImagesResponse {
    return new SearchImagesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchImagesResponse {
    return new SearchImagesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchImagesResponse {
    return new SearchImagesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SearchImagesResponse | PlainMessage<SearchImagesResponse> | undefined, b: SearchImagesResponse | PlainMessage<SearchImagesResponse> | undefined): boolean {
    return proto3.util.equals(SearchImagesResponse, a, b);
  }
}

