// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/connections.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { User } from "./user_pb.js";
import { AvailableContent, UserInterfaceFeatures } from "./features_pb.js";
import { InteractionPermissions } from "./interaction_permissions_pb.js";

/**
 * @generated from enum avn.connect.v1.ConnectionStatus
 */
export enum ConnectionStatus {
  /**
   * @generated from enum value: CONNECTION_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CONNECTION_STATUS_ANONYMOUS = 1;
   */
  ANONYMOUS = 1,

  /**
   * @generated from enum value: CONNECTION_STATUS_AUTHENTICATED = 2;
   */
  AUTHENTICATED = 2,

  /**
   * @generated from enum value: CONNECTION_STATUS_ADMIN = 3;
   */
  ADMIN = 3,

  /**
   * @generated from enum value: CONNECTION_STATUS_OWNER = 4;
   */
  OWNER = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ConnectionStatus)
proto3.util.setEnumType(ConnectionStatus, "avn.connect.v1.ConnectionStatus", [
  { no: 0, name: "CONNECTION_STATUS_UNSPECIFIED" },
  { no: 1, name: "CONNECTION_STATUS_ANONYMOUS" },
  { no: 2, name: "CONNECTION_STATUS_AUTHENTICATED" },
  { no: 3, name: "CONNECTION_STATUS_ADMIN" },
  { no: 4, name: "CONNECTION_STATUS_OWNER" },
]);

/**
 * @generated from message avn.connect.v1.ConnectionInstance
 */
export class ConnectionInstance extends Message<ConnectionInstance> {
  /**
   * @generated from field: avn.connect.v1.ConnectionCredentials credentials = 1;
   */
  credentials?: ConnectionCredentials;

  /**
   * @generated from field: string client_id = 2;
   */
  clientId = "";

  /**
   * @generated from field: optional avn.connect.v1.User user = 3;
   */
  user?: User;

  /**
   * Dimension status and permissions
   *
   * @generated from field: avn.connect.v1.ConnectionStatus status = 6;
   */
  status = ConnectionStatus.UNSPECIFIED;

  /**
   * @generated from field: avn.connect.v1.AvailableContent content = 7;
   */
  content?: AvailableContent;

  /**
   * @generated from field: avn.connect.v1.InteractionPermissions permissions = 8;
   */
  permissions?: InteractionPermissions;

  /**
   * @generated from field: avn.connect.v1.UserInterfaceFeatures features = 9;
   */
  features?: UserInterfaceFeatures;

  constructor(data?: PartialMessage<ConnectionInstance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.ConnectionInstance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "credentials", kind: "message", T: ConnectionCredentials },
    { no: 2, name: "client_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user", kind: "message", T: User, opt: true },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(ConnectionStatus) },
    { no: 7, name: "content", kind: "message", T: AvailableContent },
    { no: 8, name: "permissions", kind: "message", T: InteractionPermissions },
    { no: 9, name: "features", kind: "message", T: UserInterfaceFeatures },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectionInstance {
    return new ConnectionInstance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectionInstance {
    return new ConnectionInstance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectionInstance {
    return new ConnectionInstance().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectionInstance | PlainMessage<ConnectionInstance> | undefined, b: ConnectionInstance | PlainMessage<ConnectionInstance> | undefined): boolean {
    return proto3.util.equals(ConnectionInstance, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.ConnectionCredentials
 */
export class ConnectionCredentials extends Message<ConnectionCredentials> {
  /**
   * @generated from field: string connection_id = 1;
   */
  connectionId = "";

  /**
   * @generated from field: string connection_secret = 2;
   */
  connectionSecret = "";

  /**
   * @generated from field: string dimension_id = 3;
   */
  dimensionId = "";

  constructor(data?: PartialMessage<ConnectionCredentials>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.ConnectionCredentials";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "connection_secret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "dimension_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectionCredentials {
    return new ConnectionCredentials().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectionCredentials {
    return new ConnectionCredentials().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectionCredentials {
    return new ConnectionCredentials().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectionCredentials | PlainMessage<ConnectionCredentials> | undefined, b: ConnectionCredentials | PlainMessage<ConnectionCredentials> | undefined): boolean {
    return proto3.util.equals(ConnectionCredentials, a, b);
  }
}

