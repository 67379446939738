// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/categories.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddChildrenRequest, CopyEntityRequest, CreateEntityRequest, CreateEntityResponse, DeleteEntityRequest, EntityInfoListRequest, EntityInfoListResponse, GetEntityRequest, RemoveChildrenRequest, SetEntityPropertiesRequest } from "./entities_pb.js";
import { Category } from "./categories_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";
import { AddTagsRequest, RemoveTagsRequest } from "./tags_pb.js";

/**
 * @generated from service avn.connect.v1.CategoryService
 */
export const CategoryService = {
  typeName: "avn.connect.v1.CategoryService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.CategoryService.GetCategory
     */
    getCategory: {
      name: "GetCategory",
      I: GetEntityRequest,
      O: Category,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.CategoryService.CreateCategory
     */
    createCategory: {
      name: "CreateCategory",
      I: CreateEntityRequest,
      O: CreateEntityResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.CategoryService.DeleteCategory
     */
    deleteCategory: {
      name: "DeleteCategory",
      I: DeleteEntityRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.CategoryService.CopyCategory
     */
    copyCategory: {
      name: "CopyCategory",
      I: CopyEntityRequest,
      O: CreateEntityResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get organization categories
     *
     * @generated from rpc avn.connect.v1.CategoryService.GetOrganizationCategories
     */
    getOrganizationCategories: {
      name: "GetOrganizationCategories",
      I: EntityInfoListRequest,
      O: EntityInfoListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get user categories (previously known as playlists)
     *
     * @generated from rpc avn.connect.v1.CategoryService.GetUserCategories
     */
    getUserCategories: {
      name: "GetUserCategories",
      I: EntityInfoListRequest,
      O: EntityInfoListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get all published activities in the given category
     *
     * @generated from rpc avn.connect.v1.CategoryService.GetActivities
     */
    getActivities: {
      name: "GetActivities",
      I: EntityInfoListRequest,
      O: EntityInfoListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Activity management
     *
     * @generated from rpc avn.connect.v1.CategoryService.AddActivities
     */
    addActivities: {
      name: "AddActivities",
      I: AddChildrenRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.CategoryService.RemoveActivities
     */
    removeActivities: {
      name: "RemoveActivities",
      I: RemoveChildrenRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Tag management
     *
     * @generated from rpc avn.connect.v1.CategoryService.AddTags
     */
    addTags: {
      name: "AddTags",
      I: AddTagsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.CategoryService.RemoveTags
     */
    removeTags: {
      name: "RemoveTags",
      I: RemoveTagsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.CategoryService.SetProperties
     */
    setProperties: {
      name: "SetProperties",
      I: SetEntityPropertiesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;

