// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/packages.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum avn.connect.v1.ReleaseChannel
 */
export enum ReleaseChannel {
  /**
   * @generated from enum value: RELEASE_CHANNEL_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: RELEASE_CHANNEL_ALPHA = 1;
   */
  ALPHA = 1,

  /**
   * @generated from enum value: RELEASE_CHANNEL_BETA = 2;
   */
  BETA = 2,

  /**
   * @generated from enum value: RELEASE_CHANNEL_PRODUCTION = 3;
   */
  PRODUCTION = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ReleaseChannel)
proto3.util.setEnumType(ReleaseChannel, "avn.connect.v1.ReleaseChannel", [
  { no: 0, name: "RELEASE_CHANNEL_UNSPECIFIED" },
  { no: 1, name: "RELEASE_CHANNEL_ALPHA" },
  { no: 2, name: "RELEASE_CHANNEL_BETA" },
  { no: 3, name: "RELEASE_CHANNEL_PRODUCTION" },
]);

/**
 * @generated from message avn.connect.v1.AndroidPackage
 */
export class AndroidPackage extends Message<AndroidPackage> {
  /**
   * @generated from field: int32 entity_id = 1;
   */
  entityId = 0;

  /**
   * Associated activity
   *
   * @generated from field: int32 activity_id = 2;
   */
  activityId = 0;

  /**
   * Software release channel targeted
   *
   * @generated from field: avn.connect.v1.ReleaseChannel channel = 3;
   */
  channel = ReleaseChannel.UNSPECIFIED;

  /**
   * Package name and class (ANDROID_PACKAGE_REFACTORING)
   *
   * @generated from field: string package_name = 4;
   */
  packageName = "";

  /**
   * @generated from field: optional string package_class = 5;
   */
  packageClass?: string;

  /**
   * APK file
   *
   * @generated from field: string apk_url = 6;
   */
  apkUrl = "";

  /**
   * Optional OBB files
   *
   * @generated from field: optional string obb_main_url = 7;
   */
  obbMainUrl?: string;

  /**
   * @generated from field: optional string obb_patch_url = 8;
   */
  obbPatchUrl?: string;

  /**
   * Minimum Android SDK supported
   *
   * @generated from field: int32 min_sdk = 9;
   */
  minSdk = 0;

  /**
   * Target ABI if architecture specific
   *
   * @generated from field: optional string target_abi = 10;
   */
  targetAbi?: string;

  /**
   * @generated from field: google.protobuf.Timestamp created = 11;
   */
  created?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated = 12;
   */
  updated?: Timestamp;

  constructor(data?: PartialMessage<AndroidPackage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.AndroidPackage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "activity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "channel", kind: "enum", T: proto3.getEnumType(ReleaseChannel) },
    { no: 4, name: "package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "package_class", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "apk_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "obb_main_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "obb_patch_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "min_sdk", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "target_abi", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "created", kind: "message", T: Timestamp },
    { no: 12, name: "updated", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AndroidPackage {
    return new AndroidPackage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AndroidPackage {
    return new AndroidPackage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AndroidPackage {
    return new AndroidPackage().fromJsonString(jsonString, options);
  }

  static equals(a: AndroidPackage | PlainMessage<AndroidPackage> | undefined, b: AndroidPackage | PlainMessage<AndroidPackage> | undefined): boolean {
    return proto3.util.equals(AndroidPackage, a, b);
  }
}

