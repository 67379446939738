// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/certificates.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message avn.connect.v1.Certificate
 */
export class Certificate extends Message<Certificate> {
  /**
   * SHA256 fingerprint
   *
   * @generated from field: string fingerprint = 1;
   */
  fingerprint = "";

  /**
   * Hostname that certificate is issued for
   *
   * @generated from field: string hostname = 2;
   */
  hostname = "";

  /**
   * Time range certificate is valid for
   *
   * @generated from field: google.protobuf.Timestamp valid_from = 3;
   */
  validFrom?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp valid_to = 4;
   */
  validTo?: Timestamp;

  /**
   * The private TLS certificate for servers
   *
   * @generated from field: string file_url = 5;
   */
  fileUrl = "";

  /**
   * The legacy certificate file is for use on legacy platforms like Mono
   *
   * @generated from field: string legacy_file_url = 6;
   */
  legacyFileUrl = "";

  /**
   * When the certificate was created
   *
   * @generated from field: google.protobuf.Timestamp created = 7;
   */
  created?: Timestamp;

  constructor(data?: PartialMessage<Certificate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Certificate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fingerprint", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "hostname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "valid_from", kind: "message", T: Timestamp },
    { no: 4, name: "valid_to", kind: "message", T: Timestamp },
    { no: 5, name: "file_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "legacy_file_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "created", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Certificate {
    return new Certificate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Certificate {
    return new Certificate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Certificate {
    return new Certificate().fromJsonString(jsonString, options);
  }

  static equals(a: Certificate | PlainMessage<Certificate> | undefined, b: Certificate | PlainMessage<Certificate> | undefined): boolean {
    return proto3.util.equals(Certificate, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetHostnameCertificatesRequest
 */
export class GetHostnameCertificatesRequest extends Message<GetHostnameCertificatesRequest> {
  /**
   * @generated from field: string hostname = 1;
   */
  hostname = "";

  constructor(data?: PartialMessage<GetHostnameCertificatesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetHostnameCertificatesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hostname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetHostnameCertificatesRequest {
    return new GetHostnameCertificatesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetHostnameCertificatesRequest {
    return new GetHostnameCertificatesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetHostnameCertificatesRequest {
    return new GetHostnameCertificatesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetHostnameCertificatesRequest | PlainMessage<GetHostnameCertificatesRequest> | undefined, b: GetHostnameCertificatesRequest | PlainMessage<GetHostnameCertificatesRequest> | undefined): boolean {
    return proto3.util.equals(GetHostnameCertificatesRequest, a, b);
  }
}

