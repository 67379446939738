// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/avnfs.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetAltServersRequest, GetAltServersResponse, GetFileUrlRequest, GetFileUrlResponse, GetManifestRequest, ImportUrlRequest, ImportUrlResponse, UploadManifest } from "./avnfs_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.AvnfsService
 */
export const AvnfsService = {
  typeName: "avn.connect.v1.AvnfsService",
  methods: {
    /**
     * Verify file exists and return URL if it does
     *
     * @generated from rpc avn.connect.v1.AvnfsService.GetFileUrl
     */
    getFileUrl: {
      name: "GetFileUrl",
      I: GetFileUrlRequest,
      O: GetFileUrlResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DISABLED UNTIL METADATA ISSUES CAN BE RESOLVED S3_PUT_INSECURE
     * rpc GetPutManifest(GetManifestRequest) returns (UploadManifest);
     *
     * @generated from rpc avn.connect.v1.AvnfsService.GetPostManifest
     */
    getPostManifest: {
      name: "GetPostManifest",
      I: GetManifestRequest,
      O: UploadManifest,
      kind: MethodKind.Unary,
    },
    /**
     * Bring a remote file into AVNFS
     *
     * @generated from rpc avn.connect.v1.AvnfsService.ImportUrl
     */
    importUrl: {
      name: "ImportUrl",
      I: ImportUrlRequest,
      O: ImportUrlResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc avn.connect.v1.AvnfsService.GetAltServers
     */
    getAltServers: {
      name: "GetAltServers",
      I: GetAltServersRequest,
      O: GetAltServersResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

