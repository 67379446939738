// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/command.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Authorization } from "./authorization_pb.js";

/**
 * @generated from message avn.connect.v1.CommandRequest
 */
export class CommandRequest extends Message<CommandRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * Command to execute
   *
   * @generated from field: string command = 2;
   */
  command = "";

  /**
   * Escape output filenames with angle brackets, e.g. <output.txt>  
   *
   * @generated from field: repeated string arguments = 3;
   */
  arguments: string[] = [];

  constructor(data?: PartialMessage<CommandRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CommandRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "command", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "arguments", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CommandRequest {
    return new CommandRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CommandRequest {
    return new CommandRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CommandRequest {
    return new CommandRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CommandRequest | PlainMessage<CommandRequest> | undefined, b: CommandRequest | PlainMessage<CommandRequest> | undefined): boolean {
    return proto3.util.equals(CommandRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.CommandResponse
 */
export class CommandResponse extends Message<CommandResponse> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string stdout = 2;
   */
  stdout = "";

  /**
   * @generated from field: string stderr = 3;
   */
  stderr = "";

  /**
   * @generated from field: repeated string output_file_urls = 4;
   */
  outputFileUrls: string[] = [];

  constructor(data?: PartialMessage<CommandResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.CommandResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "stdout", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "stderr", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "output_file_urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CommandResponse {
    return new CommandResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CommandResponse {
    return new CommandResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CommandResponse {
    return new CommandResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CommandResponse | PlainMessage<CommandResponse> | undefined, b: CommandResponse | PlainMessage<CommandResponse> | undefined): boolean {
    return proto3.util.equals(CommandResponse, a, b);
  }
}

