// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/translations.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Translation, TranslationRequest } from "./translations_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.TranslationService
 */
export const TranslationService = {
  typeName: "avn.connect.v1.TranslationService",
  methods: {
    /**
     * Will be used to find the source text for a translation 
     * rpc Reverse(Translation) returns (TranslationRequest);
     * Will be used to update an existing translation
     * rpc Update(TranslationResult) returns (google.protobuf.Empty);
     *
     * @generated from rpc avn.connect.v1.TranslationService.Translate
     */
    translate: {
      name: "Translate",
      I: TranslationRequest,
      O: Translation,
      kind: MethodKind.Unary,
    },
  }
} as const;

