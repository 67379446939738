// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/permissions.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Authorization } from "./authorization_pb.js";

/**
 * @generated from enum avn.connect.v1.PermissionScope
 */
export enum PermissionScope {
  /**
   * @generated from enum value: PERMISSION_SCOPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PERMISSION_SCOPE_GLOBAL = 1;
   */
  GLOBAL = 1,

  /**
   * @generated from enum value: PERMISSION_SCOPE_INHERITED = 2;
   */
  INHERITED = 2,

  /**
   * @generated from enum value: PERMISSION_SCOPE_BRANCH = 3;
   */
  BRANCH = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(PermissionScope)
proto3.util.setEnumType(PermissionScope, "avn.connect.v1.PermissionScope", [
  { no: 0, name: "PERMISSION_SCOPE_UNSPECIFIED" },
  { no: 1, name: "PERMISSION_SCOPE_GLOBAL" },
  { no: 2, name: "PERMISSION_SCOPE_INHERITED" },
  { no: 3, name: "PERMISSION_SCOPE_BRANCH" },
]);

/**
 * @generated from message avn.connect.v1.Permission
 */
export class Permission extends Message<Permission> {
  /**
   * @generated from field: string permission_id = 1;
   */
  permissionId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: avn.connect.v1.PermissionScope scope = 3;
   */
  scope = PermissionScope.UNSPECIFIED;

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  constructor(data?: PartialMessage<Permission>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Permission";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "permission_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "scope", kind: "enum", T: proto3.getEnumType(PermissionScope) },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Permission {
    return new Permission().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Permission {
    return new Permission().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Permission {
    return new Permission().fromJsonString(jsonString, options);
  }

  static equals(a: Permission | PlainMessage<Permission> | undefined, b: Permission | PlainMessage<Permission> | undefined): boolean {
    return proto3.util.equals(Permission, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.IsPermittedRequest
 */
export class IsPermittedRequest extends Message<IsPermittedRequest> {
  /**
   * @generated from field: avn.connect.v1.Authorization auth = 1;
   */
  auth?: Authorization;

  /**
   * The permissions to test
   *
   * @generated from field: string permissions_id = 2;
   */
  permissionsId = "";

  /**
   * The user under test (unset to use the ID of the authorizing user)
   *
   * @generated from field: optional int32 user_id = 3;
   */
  userId?: number;

  /**
   * The organization for non-global permissions
   *
   * @generated from field: optional int32 organization_id = 4;
   */
  organizationId?: number;

  constructor(data?: PartialMessage<IsPermittedRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.IsPermittedRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "auth", kind: "message", T: Authorization },
    { no: 2, name: "permissions_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IsPermittedRequest {
    return new IsPermittedRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IsPermittedRequest {
    return new IsPermittedRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IsPermittedRequest {
    return new IsPermittedRequest().fromJsonString(jsonString, options);
  }

  static equals(a: IsPermittedRequest | PlainMessage<IsPermittedRequest> | undefined, b: IsPermittedRequest | PlainMessage<IsPermittedRequest> | undefined): boolean {
    return proto3.util.equals(IsPermittedRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.IsPermittedResponse
 */
export class IsPermittedResponse extends Message<IsPermittedResponse> {
  /**
   * @generated from field: bool value = 1;
   */
  value = false;

  constructor(data?: PartialMessage<IsPermittedResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.IsPermittedResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IsPermittedResponse {
    return new IsPermittedResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IsPermittedResponse {
    return new IsPermittedResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IsPermittedResponse {
    return new IsPermittedResponse().fromJsonString(jsonString, options);
  }

  static equals(a: IsPermittedResponse | PlainMessage<IsPermittedResponse> | undefined, b: IsPermittedResponse | PlainMessage<IsPermittedResponse> | undefined): boolean {
    return proto3.util.equals(IsPermittedResponse, a, b);
  }
}

