// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/categories.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Translation } from "./translations_pb.js";

/**
 * @generated from message avn.connect.v1.Category
 */
export class Category extends Message<Category> {
  /**
   * @generated from field: int32 entity_id = 1;
   */
  entityId = 0;

  /**
   * @generated from field: avn.connect.v1.Translation name = 2;
   */
  name?: Translation;

  /**
   * @generated from field: string icon_url = 3;
   */
  iconUrl = "";

  /**
   * @generated from field: string preview_url = 4;
   */
  previewUrl = "";

  /**
   * @generated from field: google.protobuf.Timestamp updated = 5;
   */
  updated?: Timestamp;

  /**
   * @generated from field: optional avn.connect.v1.Translation description = 6;
   */
  description?: Translation;

  /**
   * @generated from field: optional avn.connect.v1.Translation instructions = 7;
   */
  instructions?: Translation;

  /**
   * @generated from field: string language_id = 8;
   */
  languageId = "";

  /**
   * @generated from field: repeated int32 tags = 9;
   */
  tags: number[] = [];

  /**
   * @generated from field: optional google.protobuf.Timestamp published = 10;
   */
  published?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp featured = 11;
   */
  featured?: Timestamp;

  /**
   * @generated from oneof avn.connect.v1.Category.owner
   */
  owner: {
    /**
     * @generated from field: int32 organization_id = 12;
     */
    value: number;
    case: "organizationId";
  } | {
    /**
     * @generated from field: int32 user_id = 13;
     */
    value: number;
    case: "userId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: bool deleted = 14;
   */
  deleted = false;

  /**
   * @generated from field: int32 item_count = 15;
   */
  itemCount = 0;

  /**
   * @generated from field: int32 track_count = 16;
   */
  trackCount = 0;

  constructor(data?: PartialMessage<Category>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Category";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "message", T: Translation },
    { no: 3, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "preview_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updated", kind: "message", T: Timestamp },
    { no: 6, name: "description", kind: "message", T: Translation, opt: true },
    { no: 7, name: "instructions", kind: "message", T: Translation, opt: true },
    { no: 8, name: "language_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "tags", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 10, name: "published", kind: "message", T: Timestamp, opt: true },
    { no: 11, name: "featured", kind: "message", T: Timestamp, opt: true },
    { no: 12, name: "organization_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "owner" },
    { no: 13, name: "user_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "owner" },
    { no: 14, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "item_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "track_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Category {
    return new Category().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Category {
    return new Category().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Category {
    return new Category().fromJsonString(jsonString, options);
  }

  static equals(a: Category | PlainMessage<Category> | undefined, b: Category | PlainMessage<Category> | undefined): boolean {
    return proto3.util.equals(Category, a, b);
  }
}

