// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/channels.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Translation } from "./translations_pb.js";

/**
 * @generated from message avn.connect.v1.Channel
 */
export class Channel extends Message<Channel> {
  /**
   * @generated from field: int32 entity_id = 1;
   */
  entityId = 0;

  /**
   * @generated from field: avn.connect.v1.Translation name = 2;
   */
  name?: Translation;

  /**
   * @generated from field: string icon_url = 3;
   */
  iconUrl = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 4;
   */
  created?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated = 5;
   */
  updated?: Timestamp;

  /**
   * @generated from field: optional avn.connect.v1.Translation description = 6;
   */
  description?: Translation;

  constructor(data?: PartialMessage<Channel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Channel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "message", T: Translation },
    { no: 3, name: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "created", kind: "message", T: Timestamp },
    { no: 5, name: "updated", kind: "message", T: Timestamp },
    { no: 6, name: "description", kind: "message", T: Translation, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Channel {
    return new Channel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Channel {
    return new Channel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Channel {
    return new Channel().fromJsonString(jsonString, options);
  }

  static equals(a: Channel | PlainMessage<Channel> | undefined, b: Channel | PlainMessage<Channel> | undefined): boolean {
    return proto3.util.equals(Channel, a, b);
  }
}

