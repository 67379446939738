// @generated by protoc-gen-es v1.4.2 with parameter "target=ts"
// @generated from file avn/connect/v1/passes.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message avn.connect.v1.Pass
 */
export class Pass extends Message<Pass> {
  /**
   * @generated from field: string pass_id = 1;
   */
  passId = "";

  /**
   * @generated from field: int32 capacity = 2;
   */
  capacity = 0;

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: optional google.protobuf.Timestamp expires = 4;
   */
  expires?: Timestamp;

  constructor(data?: PartialMessage<Pass>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.Pass";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pass_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "capacity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "expires", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Pass {
    return new Pass().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Pass {
    return new Pass().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Pass {
    return new Pass().fromJsonString(jsonString, options);
  }

  static equals(a: Pass | PlainMessage<Pass> | undefined, b: Pass | PlainMessage<Pass> | undefined): boolean {
    return proto3.util.equals(Pass, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetPassRequest
 */
export class GetPassRequest extends Message<GetPassRequest> {
  /**
   * @generated from field: string pass_id = 1;
   */
  passId = "";

  constructor(data?: PartialMessage<GetPassRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetPassRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pass_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPassRequest {
    return new GetPassRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPassRequest {
    return new GetPassRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPassRequest {
    return new GetPassRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPassRequest | PlainMessage<GetPassRequest> | undefined, b: GetPassRequest | PlainMessage<GetPassRequest> | undefined): boolean {
    return proto3.util.equals(GetPassRequest, a, b);
  }
}

/**
 * @generated from message avn.connect.v1.GetPassResponse
 */
export class GetPassResponse extends Message<GetPassResponse> {
  /**
   * @generated from field: avn.connect.v1.Pass result = 1;
   */
  result?: Pass;

  constructor(data?: PartialMessage<GetPassResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "avn.connect.v1.GetPassResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "result", kind: "message", T: Pass },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPassResponse {
    return new GetPassResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPassResponse {
    return new GetPassResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPassResponse {
    return new GetPassResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPassResponse | PlainMessage<GetPassResponse> | undefined, b: GetPassResponse | PlainMessage<GetPassResponse> | undefined): boolean {
    return proto3.util.equals(GetPassResponse, a, b);
  }
}

