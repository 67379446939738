// @generated by protoc-gen-connect-es v1.1.3 with parameter "target=ts"
// @generated from file avn/connect/v1/neighbors.proto (package avn.connect.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { RegisterNeighborServerRequest, RegisterNeighborServerResponse } from "./neighbors_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service avn.connect.v1.NeighborService
 */
export const NeighborService = {
  typeName: "avn.connect.v1.NeighborService",
  methods: {
    /**
     * @generated from rpc avn.connect.v1.NeighborService.RegisterNeighborServer
     */
    registerNeighborServer: {
      name: "RegisterNeighborServer",
      I: RegisterNeighborServerRequest,
      O: RegisterNeighborServerResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

